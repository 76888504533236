import { CustomTheme } from "../types";

// ----------------------------------------------------------------------

export default function LoadingButton(theme: CustomTheme) {
  return {
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          backgroundColor: theme.styles.buttons.primary["button-primary"],
          boxShadow: "none",
          color: theme.styles.colors.white,
          "&:hover": {
            backgroundColor: theme.styles.buttons.primary["button-primary"],
          },
          "&.MuiButton-text": {
            "& .MuiLoadingButton-startIconPendingStart": {
              marginLeft: 0,
            },
            "& .MuiLoadingButton-endIconPendingEnd": {
              marginRight: 0,
            },
          },
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.styles.stroke["stroke-black"]}`,
          backgroundColor: "transparent",
          color: theme.styles.text["text-black"],
          "&:hover": {
            backgroundColor: theme.styles.buttons.primary["button-primary"],
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.styles.buttons.primary["button-primary"],
          },
        },
      },
    },
  };
}
