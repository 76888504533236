import firebase from "firebase/compat/app";
import { ProviderAccessToken } from "./audit-logs/log";
import { GoogleLoginResponse } from "react-google-login";

// ----------------------------------------------------------------------

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AuthUser = null | Record<string, any>;

export type Roles = { usrRoles?: string[] };

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  roles?: Roles;
  therapistId?: string;
  user: AuthUser;
  isTherapist: boolean;
  checkedIn: boolean;
  checkInTime?: Date;
  checkOutTime?: Date;
  lastEventAt?: Date;
  providerAccessToken?: { uid: string; accessToken: string }; // access token retrieved via google login to perform Logs fetching.
  registeredMFA: boolean;
  shouldVerifyEmail: boolean;
  setShouldVerifyEmail: (shouldVerifyEmail: boolean) => void;
};

export type FirebaseContextType = AuthState & {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  therapistId?: string;
  roles: Roles;
  isTherapist: boolean;
  registeredMFA: boolean;
  method: "firebase";
  checkedIn: boolean;
  checkInTime?: Date;
  checkOutTime?: Date;
  lastEventAt?: Date;
  login: (
    email: string,
    password: string
  ) => Promise<firebase.auth.UserCredential>;
  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<void>;
  loginWithGoogle: (
    googleUser: GoogleLoginResponse
  ) => Promise<firebase.auth.UserCredential>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: (user: AuthUser) => Promise<void>;
  providerAccessToken?: ProviderAccessToken;
  showLoginSuccessMessage: boolean;
  attemptingLogin: boolean;
  setAttemptingLogin: (value: boolean) => void;
  toggleCheckedIn: (checkedIn: boolean, checkInTime: Date) => void;
  updateLastEventTime: (charsTypes: number) => void;
};

export enum AuthErrorCode {
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN = "auth/requires-recent-login",
  INVALID_CODE = "auth/invalid-verification-code",
  INVALID_PASSWORD = "auth/wrong-password",
}

export enum MFAStep {
  VERIFY = "verify",
  SUCCESS = "success",
  ERROR = "error",
  ENROLL = "enroll",
  LOGGING_IN = "loggingIn",
  REQUEST = "request",
  PHONE_NUMBER = "phoneNumber",
  REFRESH_LOGIN = "refreshLogin",
  CONFIRM = "confirm",
}
