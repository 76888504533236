import { ReactNode, useEffect, useState, useRef } from "react";
import { Alert, AlertTitle, Container } from "@mui/material";
import useAuth from "../hooks/useAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { hasValidPermissions } from "../utils/helpers";
import { CCRolesPossibleVals } from "../@types/role/CCRoles";

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: Array<CCRolesPossibleVals>;
  children: ReactNode | string;
};

export const useCurrentRole = () => {
  const { user, therapistId } = useAuth();
  // usrRoles are the roles coming from customClaims.
  const [usrRoles, setUsrRoles] = useState<Array<CCRolesPossibleVals>>([]);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    firebase
      ?.auth()
      ?.currentUser?.getIdTokenResult()
      ?.then((idTokenResult: any) => {
        if (isMounted.current) {
          setUsrRoles(idTokenResult?.claims?.usrRoles || []);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setUsrRoles([]);
        }
        console.log(error);
      });

    return () => {
      isMounted.current = false;
    };
  }, [user, therapistId]);

  return usrRoles;
};

export default function RoleBasedGuard({
  accessibleRoles,
  children,
}: RoleBasedGuardProp) {
  const currentRoles = useCurrentRole();

  let roleMessage = "You do not have permission to access this page";

  if (!hasValidPermissions(currentRoles, accessibleRoles)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          {roleMessage}
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
