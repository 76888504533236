import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Drawer, Stack, Typography } from "@mui/material";

// hooks
import useAuth from "../../hooks/useAuth";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";

// components
import MyAvatar from "../../components/MyAvatar";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
import { userApi } from "redux/userApi";
import { SelectMFAType } from "../../components/SelectMFAType";
import { MfaType } from "../../utils/constants";
import { UnEnrollMfaAuthenticationDialog } from "../../components/UnenrollMfaAuthenticationDialog";
import KyanLogo from "../../components/icons/KyanLogo";
import { ThemeProps, useCustomTheme } from "../../theme/CustomThemeProvider";
//

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

const AccountStyle = styled("div")<ThemeProps>(({ theme }) => ({
  borderRadius: theme.borderRadii?.sm,
  backgroundColor: theme.styles.background["bg-secondary"],
}));

const UserInfo = styled("div")(({ theme }) => ({
  padding: theme.spacing(2, 2.5),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const StyledScrollBar = styled(Scrollbar)<ThemeProps>(({ theme }) => ({
  background: theme.styles.background["bg-primary"],
}));

const LogoutButton = styled(Typography)<ThemeProps>(({ theme }) => ({
  padding: 0,
  margin: 0,
  fontSize: 12,
  textDecoration: "underline",
  color: theme.styles.text["text-primary"],
  justifyContent: "flex-start",
  cursor: "pointer",
}));

const MFAButton = styled(Typography)<ThemeProps>(({ theme }) => ({
  margin: 0,
  paddingTop: 2,
  paddingBottom: 4,
  fontSize: 12,
  textDecoration: "underline",
  color: theme.styles.text["text-primary"],
  justifyContent: "flex-start",
  cursor: "pointer",
}));

// ----------------------------------------------------------------------

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: DashboardSidebarProps) {
  const [mfaDialogOpen, setMfaDialogOpen] = useState<boolean>(false);
  const [showUnEnrollMfaDialog, setShowUnEnrollMfaDialog] =
    useState<boolean>(false);
  const [mfaType, setMfaType] = useState<MfaType>(MfaType.PHONE);

  const { pathname } = useLocation();
  const customTheme = useCustomTheme();

  const { user, logout } = useAuth();
  const { data: userState } = userApi.useLoadUserQuery(user?.uid);

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleMfaDialog = () => {
    setMfaDialogOpen((prev) => !prev);
  };

  function handleMFaEnrollOrUnEnroll(selectedMFAType: MfaType) {
    setShowUnEnrollMfaDialog(true);
    setMfaType(selectedMFAType);
  }

  function closeMfaModel() {
    setMfaDialogOpen(false);
    setShowUnEnrollMfaDialog(false);
  }

  const renderContent = (
    <StyledScrollBar
      sx={{
        displayPrint: "none",
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2,
          pt: 3,
          pb: 2,
          position: "relative",
          zIndex: 2,
          ...(isCollapse && {
            alignItems: "center",
          }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          px={1}
          justifyContent="space-between"
        >
          <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
            <KyanLogo />
          </Box>
        </Stack>

        {isCollapse ? (
          <MyAvatar sx={{ mx: "auto", mb: 2 }} />
        ) : (
          <AccountStyle>
            <UserInfo>
              <MyAvatar />
              <Box sx={{ ml: 2, display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body1 - regular  (0%)"
                  sx={{ color: customTheme.styles.text["text-primary"] }}
                >
                  {userState?.fullname || user?.displayName || ""}
                </Typography>

                <MFAButton variant="link 1 - regular" onClick={handleMfaDialog}>
                  Enable/Disable 2FA
                </MFAButton>

                <LogoutButton variant="link 1 - regular" onClick={logout}>
                  Log out
                </LogoutButton>
              </Box>
            </UserInfo>
          </AccountStyle>
        )}
      </Stack>

      <NavSection
        sx={{ position: "relative", zIndex: 2 }}
        isShow={!isCollapse}
      />
    </StyledScrollBar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
      className="no-print"
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              borderRightColor: customTheme.styles.stroke["stroke-primary"],
              bgcolor: "background.default",
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) =>
                  alpha(theme.palette.background.default, 0.88),
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>

        {mfaDialogOpen && (
          <SelectMFAType
            open={mfaDialogOpen}
            onClose={handleMfaDialog}
            setShowUnEnrollMfa={handleMFaEnrollOrUnEnroll}
          />
        )}

        <UnEnrollMfaAuthenticationDialog
          open={showUnEnrollMfaDialog}
          onClose={() => setShowUnEnrollMfaDialog(false)}
          mfaType={mfaType}
          hideMfaModel={closeMfaModel}
        />
      </MHidden>
    </RootStyle>
  );
}
