import { createContext, ReactNode, useEffect, useState, useRef } from "react";
import { firebaseAuth } from "./FirebaseContext";
import * as CloudFunctions from "utils/firebase/cloudFunctions";
import { UnknownError } from "../@types/settings";

//@ts-ignore
export type EThreeType = window.E3kit.EThree;

type EThreeContextType = {
  eThree: EThreeType | null;
  isLoading: boolean;
  failed: boolean;
  eThreeAvailable: boolean;
};

export const EThreeContext = createContext<EThreeContextType>({
  eThree: null,
  isLoading: true,
  failed: false,
  eThreeAvailable: false,
});

type Props = {
  children: ReactNode;
};

async function retrieveVirgilToken() {
  const result = await CloudFunctions.getVirgilJwt();
  return result.data.token;
}

export default function EThreeProvider({ children }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [failed, setFailed] = useState<boolean>(false);
  const eThree: any = useRef<EThreeType | null>(null);

  useEffect(() => {
    const subscription = firebaseAuth().onAuthStateChanged(async (user) => {
      setIsLoading(true);

      if (!user) {
        setIsLoading(false);
        setFailed(false);
        if (eThree.current !== null) {
          await eThree.current.cleanup();
        }
        return;
      }

      // @ts-ignore
      eThree.current = await window.E3kit.EThree.initialize(
        retrieveVirgilToken
      );

      // Check if we are registered
      try {
        await eThree.current.findUsers(user.uid);
      } catch (error: UnknownError) {
        await eThree.current.register();
        await eThree.current.backupPrivateKey(user.uid);
      }

      const hasLocalPrivateKey = await eThree.current.hasLocalPrivateKey();
      if (hasLocalPrivateKey) {
        setIsLoading(false);
        return;
      }

      try {
        await eThree.current.restorePrivateKey(user.uid);
        setIsLoading(false);
      } catch (error: UnknownError) {
        if (error.name === "PrivateKeyNoBackupError") {
          setIsLoading(false);
          setFailed(true);
          return;
        }
      }
    });
    return () => {
      subscription();
    };
  }, []);

  return (
    <EThreeContext.Provider
      value={{
        eThree: eThree.current,
        isLoading,
        failed,
        eThreeAvailable: !isLoading && !failed,
      }}
    >
      {children}
    </EThreeContext.Provider>
  );
}
