import primitives from "./primitives";

const semantics = {
  "light-mode": {
    text: {
      "text-inverted": primitives.beige["beige-50"],
      "text-grey": primitives.black["black-600"],
      "text-primary": primitives.black["black-950"],
      "text-black": primitives.black["black-950"],
      "text-disabled": primitives.black["black-500"],
      "text-secondary": primitives.black["black-700"],
      "text-white": primitives.beige["beige-50"],
      "text-tertiary": primitives.black["black-600"],
      "text-error": primitives.red["red-700"],
    },
    background: {
      "bg-primary": primitives.beige["beige-50"],
      "bg-secondary": primitives.beige["beige-200"],
      "bg-tertiary": primitives.beige["beige-100"],
      "bg-blue-50": primitives.blue["blue-50"],
      "bg-blue-100": primitives.blue["blue-100"],
      "bg-quaternary": primitives.beige["beige-300"],
      "bg-disabled": primitives.black["black-200"],
      "bg-red": primitives.red["red-50"],
    },
    buttons: {
      primary: {
        "button-primary": primitives.black["black-950"],
        "button-primary-hover": primitives.black["black-800"],
        "button-primary-black": primitives.black["black-950"],
      },
      tertiary: {
        "button-tertiary-hover": primitives.black["black-950"],
        "button-counselling-primary-hover": primitives.black["black-950"],
      },
      secondary: {
        "button-secondary-hover": primitives.beige["beige-400"],
        "button-secondary-pressed": primitives.beige["beige-600"],
        "button-secondary": primitives.beige["beige-200"],
      },
      disabled: {
        "button-primary-disabled": primitives.black["black-300"],
        "buton-secondary-disabled": primitives.beige["beige-100"],
        "button-tertiary-disabled": primitives.black["black-400"],
        "button-counselling-disabled": primitives.blue["blue-50"],
      },
    },
    icons: {
      "icon-primary": primitives.black["black-950"],
      "icon-inverted": primitives.beige["beige-50"],
      "icon-active": primitives.black["black-950"],
      "icon-secondary": primitives.black["black-700"],
      "icon-black": primitives.black["black-950"],
      "icon-blue primary": primitives.blue["blue-500"],
      "icon-blue secondary": primitives.azure["azure-400"],
      "icon-white": primitives.white.white,
      "icon-brown": primitives.red["red-600"],
      "icon-disabled": primitives.black["black-500"],
    },
    stroke: {
      "stroke-primary": primitives.beige["beige-300"],
      "stroke-secondary": primitives.black["black-950"],
      "stroke-inverse": primitives.black["black-950"],
      "stroke-black": primitives.black["black-950"],
      "stroke-tertiary": primitives.beige["beige-100"],
      "stroke-quaternary": primitives.beige["beige-600"],
    },
    colors: {
      "action blue": {
        "blue-primary": primitives.blue["blue-300"],
        "blue-chat": primitives.azure["azure-600"],
        "blue-secondary": primitives.blue["blue-200"],
        "blue-tertiary": primitives.blue["blue-100"],
        "blue-quaternary": primitives.blue["blue-50"],
      },
      "info green": {
        "green-primary": primitives.green["green-500"],
        "green-secondary": primitives.green["green-200"],
        "green-tertiary": primitives.green["green-600"],
      },
      voilet: {
        "voilet-primary": primitives.voilet["violet-500"],
        "voilet-secondary": primitives.voilet["violet-400"],
      },
      azure: {
        "azure-secondary": primitives.azure["azure-300"],
      },
      "bottom nav": {
        reflection: primitives.torquoise["turquoise-300"],
        library: primitives.red["red-200"],
        journey: primitives.green["green-500"],
        counselling: primitives.blue["blue-300"],
        me: primitives.voilet["violet-400"],
      },
      theme: {
        prepare: primitives.torquoise["turquoise-500"],
        care: primitives.green["green-700"],
        relax: primitives.voilet["violet-400"],
        motivate: primitives.yellow["yellow-200"],
        lead: primitives.azure["azure-400"],
        accept: primitives.emarald["emarald-300"],
        relate: primitives.pink["pink-200"],
        personalise: primitives.fuchsia["fuchsia-300"],
        organise: primitives.yellow["yellow-600"],
        grow: primitives.green["green-400"],
        align: primitives.brown["brown-400"],
        connect: primitives.beige["beige-600"],
        inspect: primitives.pink["pink-400"],
        "be present": primitives.moss["moss-400"],
        release: primitives.purple["purple-400"],
        energise: primitives.orange["orange-600"],
        "blue-chat": primitives.azure["azure-600"],
        "yellow-card": primitives.yellow["yellow-350"],
        yellow300: primitives.yellow["yellow-300"],
      },
      ratings: {
        high: primitives.green["green-300"],
        "very high": primitives.green["green-500"],
        moderate: primitives.purple["purple-200"],
        low: primitives.red["red-200"],
        "very low": primitives.orange["orange-600"],
      },
      white: {
        white: primitives.white.white,
      },
      beige: {
        "beige-secondary": primitives.taupe["taupe-400"],
        "beige-primary": primitives.beige["beige-200"],
      },
    },
  },
  "dark-mode": {
    text: {
      "text-inverted": primitives.black["black-950"],
      "text-grey": primitives.black["black-600"],
      "text-primary": primitives.beige["beige-50"],
      "text-black": primitives.black["black-950"],
      "text-disabled": primitives.black["black-600"],
      "text-secondary": primitives.black["black-400"],
      "text-white": primitives.beige["beige-50"],
      "text-tertiary": primitives.black["black-500"],
      "text-error": primitives.red["red-400"],
    },
    background: {
      "bg-primary": primitives.black["black-950"],
      "bg-secondary": primitives.black["black-900"],
      "bg-tertiary": primitives.black["black-800"],
      "bg-blue-50": primitives.blue["blue-50"],
      "bg-blue-100": primitives.blue["blue-100"],
      "bg-quaternary": primitives.black["black-600"],
      "bg-disabled": primitives.black["black-200"],
      "bg-red": primitives.red["red-50"],
    },
    buttons: {
      primary: {
        "button-primary": primitives.beige["beige-50"],
        "button-primary-hover": primitives.beige["beige-200"],
        "button-primary-black": primitives.black["black-950"],
      },
      tertiary: {
        "button-tertiary-hover": primitives.beige["beige-50"],
        "button-counselling-primary-hover": primitives.black["black-950"],
      },
      secondary: {
        "button-secondary-hover": primitives.black["black-700"],
        "button-secondary-pressed": primitives.black["black-500"],
        "button-secondary": primitives.black["black-900"],
      },
      disabled: {
        "button-primary-disabled": primitives.black["black-400"],
        "buton-secondary-disabled": primitives.black["black-900"],
        "button-tertiary-disabled": primitives.black["black-800"],
        "button-counselling-disabled": primitives.blue["blue-950"],
      },
    },
    icons: {
      "icon-primary": primitives.beige["beige-50"],
      "icon-inverted": primitives.black["black-950"],
      "icon-active": primitives.black["black-950"],
      "icon-secondary": primitives.black["black-300"],
      "icon-black": primitives.black["black-950"],
      "icon-blue primary": primitives.blue["blue-500"],
      "icon-blue secondary": primitives.azure["azure-400"],
      "icon-white": primitives.white.white,
      "icon-brown": primitives.red["red-600"],
      "icon-disabled": primitives.black["black-600"],
    },
    stroke: {
      "stroke-primary": primitives.black["black-700"],
      "stroke-secondary": primitives.black["black-400"],
      "stroke-inverse": primitives.beige["beige-50"],
      "stroke-black": primitives.black["black-950"],
      "stroke-tertiary": primitives.black["black-900"],
      "stroke-quaternary": primitives.black["black-600"],
    },
    colors: {
      "action blue": {
        "blue-primary": primitives.blue["blue-300"],
        "blue-chat": primitives.azure["azure-600"],
        "blue-secondary": primitives.blue["blue-200"],
        "blue-tertiary": primitives.blue["blue-100"],
        "blue-quaternary": primitives.blue["blue-50"],
      },
      "info green": {
        "green-primary": primitives.green["green-500"],
        "green-secondary": primitives.green["green-200"],
        "green-tertiary": primitives.green["green-600"],
      },
      voilet: {
        "voilet-primary": primitives.voilet["violet-500"],
        "voilet-secondary": primitives.voilet["violet-400"],
      },
      azure: {
        "azure-secondary": primitives.azure["azure-300"],
      },
      "bottom nav": {
        reflection: primitives.torquoise["turquoise-300"],
        library: primitives.red["red-200"],
        journey: primitives.green["green-500"],
        counselling: primitives.blue["blue-300"],
        me: primitives.voilet["violet-400"],
      },
      theme: {
        prepare: primitives.torquoise["turquoise-500"],
        care: primitives.green["green-700"],
        relax: primitives.voilet["violet-400"],
        motivate: primitives.yellow["yellow-200"],
        lead: primitives.azure["azure-400"],
        accept: primitives.emarald["emarald-300"],
        relate: primitives.pink["pink-200"],
        personalise: primitives.fuchsia["fuchsia-300"],
        organise: primitives.yellow["yellow-600"],
        grow: primitives.green["green-400"],
        align: primitives.brown["brown-400"],
        connect: primitives.beige["beige-600"],
        inspect: primitives.pink["pink-400"],
        "be present": primitives.moss["moss-400"],
        release: primitives.purple["purple-400"],
        energise: primitives.orange["orange-600"],
        "blue-chat": primitives.azure["azure-600"],
        "yellow-card": primitives.yellow["yellow-350"],
        yellow300: primitives.yellow["yellow-300"],
      },
      ratings: {
        high: primitives.green["green-300"],
        "very high": primitives.green["green-500"],
        moderate: primitives.purple["purple-200"],
        low: primitives.red["red-200"],
        "very low": primitives.orange["orange-600"],
      },
      white: {
        white: primitives.white.white,
      },
      beige: {
        "beige-secondary": primitives.black["black-700"],
        "beige-primary": primitives.beige["beige-200"],
      },
    },
  },
};
export default semantics;
