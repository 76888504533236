import { IconProps } from "../NavItemIcon";

export default function CareExperience({ color, backgroundColor }: IconProps) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 27.0024V25.0024C26 23.9416 25.5786 22.9242 24.8284 22.174C24.0783 21.4239 23.0609 21.0024 22 21.0024H14C12.9391 21.0024 11.9217 21.4239 11.1716 22.174C10.4214 22.9242 10 23.9416 10 25.0024V27.0024"
        stroke={color}
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 19.0024C21.3137 19.0024 24 16.3161 24 13.0024C24 9.68873 21.3137 7.00244 18 7.00244C14.6863 7.00244 12 9.68873 12 13.0024C12 16.3161 14.6863 19.0024 18 19.0024Z"
        fill={color}
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 27.0024V25.0024C26 23.9416 25.5786 22.9242 24.8284 22.174C24.0783 21.4239 23.0609 21.0024 22 21.0024H14C12.9391 21.0024 11.9217 21.4239 11.1716 22.174C10.4214 22.9242 10 23.9416 10 25.0024V27.0024"
        stroke={backgroundColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 17.0024C20.2091 17.0024 22 15.2116 22 13.0024C22 10.7933 20.2091 9.00244 18 9.00244C15.7909 9.00244 14 10.7933 14 13.0024C14 15.2116 15.7909 17.0024 18 17.0024Z"
        stroke={backgroundColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
