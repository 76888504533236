import { useEffect, useRef, useState } from "react";
import {
  multiFactor,
  TotpMultiFactorGenerator,
  TotpSecret,
} from "firebase/auth";
import { Box, Typography } from "@mui/material";
import { ButtonLink } from "components/ButtonLink";
import { PATHS } from "routes/paths";
import { AuthErrorCode, MFAStep } from "../../@types/authentication";
import LoadingScreen from "../../components/LoadingScreen";
import firebase from "firebase/compat";
import { EmailVerificationDialog } from "../../components/EmailVerificationDialog";
import { isProviderUsed } from "../../utils/isProviderUsed";
import {
  PasswordFormValues,
  ReauthenticateForm,
} from "../../components/ReauthenticateForm";
import { FormikHelpers } from "formik";
import {
  QrCodeWithInputFieldValues,
  ValidateTotpCode,
} from "../../components/ValidateTotpCode";
import { UnknownError } from "../../@types/settings";
import AuthLayout from "../../layouts/AuthLayout";
import { styled } from "@mui/material/styles";
import { ThemeProps } from "../../theme/CustomThemeProvider";

const Container = styled(Box)<ThemeProps>(({ theme }) => ({
  backgroundColor: theme.styles.colors.white.white,
  padding: theme.spacingVal.xxxl,
  overflow: "hidden",
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
}));

const Title = styled(Typography)<ThemeProps>(({ theme }) => ({
  color: theme.styles.text["text-black"],
  textAlign: "center",
  marginBottom: 40,
}));

export function EnrollTOTP() {
  const [step, setStep] = useState<MFAStep>(MFAStep.ENROLL);
  const [loading, setLoading] = useState<boolean>(true);
  const [qrCodeUrl, setQrCodeUrl] = useState<string>("");
  const totpSecretRef = useRef<TotpSecret | null>(null);
  const currentUser = firebase.auth().currentUser as firebase.User;
  const { emailVerified } = currentUser;
  let title = "Success";
  let message = "Logging you in";
  if (step === MFAStep.ERROR) {
    title = "An error occurred";
    message =
      "An error occurred. Try again or contact support if the issue persists";
  }

  useEffect(() => {
    const fetchTotpSecret = async () => {
      try {
        const multiFactorSession = await multiFactor(currentUser).getSession();
        totpSecretRef.current = await TotpMultiFactorGenerator.generateSecret(
          multiFactorSession
        );
        const totpUri = totpSecretRef.current?.generateQrCodeUrl(
          currentUser?.displayName ?? currentUser?.email ?? "",
          "Kyan Health"
        );

        setQrCodeUrl(totpUri);
        setLoading(false);
      } catch (err) {
        if (String(err).includes("auth/requires-recent-login")) {
          setStep(MFAStep.REFRESH_LOGIN);
        }
        console.error("Error", err);
      }
    };

    if (currentUser?.emailVerified && step === MFAStep.ENROLL) {
      fetchTotpSecret();
    }
  }, [currentUser, step]);

  if ([MFAStep.SUCCESS, MFAStep.ERROR].includes(step)) {
    return (
      <AuthLayout headTitle={"Complete Sign-in"}>
        <Container>
          <Title variant="h4 - medium (-1%)">{title}</Title>
          <Typography textAlign="center">{message}</Typography>
          <ButtonLink to={PATHS.usageInsights} title={"Continue"} aTag />
        </Container>
      </AuthLayout>
    );
  }

  if (!emailVerified) {
    return (
      <AuthLayout headTitle={"Enroll in TOTP authentication"}>
        <Container>
          <Title variant="h4 - medium (-1%)">
            Enroll in TOTP authentication
          </Title>
          <EmailVerificationDialog />
        </Container>
      </AuthLayout>
    );
  }

  if (step === MFAStep.REFRESH_LOGIN) {
    const usesPasswordAuthentication = isProviderUsed(
      currentUser,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    );
    const usesGoogleSignIn = isProviderUsed(
      currentUser,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    );

    return (
      <AuthLayout headTitle={"Enroll in TOTP authentication"}>
        <Container>
          <Title variant="h4 - medium (-1%)">
            Enroll in TOTP authentication
          </Title>
          <ReauthenticateForm
            showPasswordAuthentication={usesPasswordAuthentication}
            showGoogleAuthentication={usesGoogleSignIn}
            email={currentUser?.email ?? ""}
            onGoogleSignIn={() => {
              currentUser
                .reauthenticateWithPopup(new firebase.auth.GoogleAuthProvider())
                .then(() => {
                  setStep(MFAStep.ENROLL);
                });
            }}
            onSubmit={async (
              values: PasswordFormValues,
              helpers: FormikHelpers<PasswordFormValues>
            ) => {
              const { password } = values;
              const cred = firebase.auth.EmailAuthProvider.credential(
                currentUser?.email ?? "",
                password
              );

              try {
                await currentUser.reauthenticateWithCredential(cred);
                setStep(MFAStep.ENROLL);
              } catch (error: UnknownError) {
                if (error.code !== AuthErrorCode.INVALID_PASSWORD) {
                  console.error(
                    "Error reauthenticating the current user",
                    error
                  );
                  setStep(MFAStep.ERROR);
                  return;
                }
                helpers.setFieldError(
                  "verificationCode",
                  "The code you entered doesn't match"
                );
              }
            }}
          />
        </Container>
      </AuthLayout>
    );
  }

  if (!currentUser) {
    return null;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <AuthLayout headTitle={"Enroll in TOTP authentication"}>
      <Container>
        <Title variant="h4 - medium (-1%)">Enroll in TOTP authentication</Title>
        <ValidateTotpCode
          qrCodeUrl={qrCodeUrl}
          onSubmit={async (
            values: QrCodeWithInputFieldValues,
            helpers: FormikHelpers<QrCodeWithInputFieldValues>
          ) => {
            try {
              const verificationCode = values?.verifyCode;

              const multiFactorAssertion =
                TotpMultiFactorGenerator.assertionForEnrollment(
                  totpSecretRef.current as TotpSecret,
                  verificationCode
                );
              await multiFactor(currentUser).enroll(
                multiFactorAssertion,
                currentUser?.displayName ?? currentUser?.email ?? ""
              );

              setStep(MFAStep.SUCCESS);
            } catch (error: UnknownError) {
              helpers.setFieldError(
                "verificationCode",
                "The code you entered doesn't match"
              );
              console.error(
                "Error occurred trying to enroll a new factor",
                error
              );
              setStep(MFAStep.ERROR);
            }
          }}
        />
      </Container>
    </AuthLayout>
  );
}
