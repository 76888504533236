import { createTheme } from "@mui/material/styles";
import React, { createContext, ReactNode } from "react";
import { styled } from "@mui/system";
import { Box as MaterialBox, ThemeProvider, Typography } from "@mui/material";
import { getBaseTheme } from "./utils/themeUtils";
import { typography } from "./tokens";
import { CustomTheme } from "./types";
import useSettings from "../hooks/useSettings";

export type ThemeProps = {
  theme?: CustomTheme;
};

const CustomThemeContext = createContext({
  theme: {} as CustomTheme,
});

declare module "@mui/material/styles" {
  interface TypographyVariants {
    [key: string]: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    [key: string]: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    [key: string]: true;
  }
}

export const CustomThemeProvider = ({ children }: { children: ReactNode }) => {
  const { themeMode } = useSettings();

  const baseTheme = getBaseTheme(themeMode !== "light");
  const keys = Object.keys(typography);

  const theme = createTheme({
    ...baseTheme,
    components: {
      MuiTypography: {
        styleOverrides: {
          // @ts-ignore
          root: ({ ownerState }: { ownerState: any }) => {
            for (const key of keys) {
              if (ownerState?.variant === key) {
                return typography[key];
              }
            }
          },
        },
      },
    },
  });

  return (
    <CustomThemeContext.Provider
      value={{ theme: theme as unknown as CustomTheme }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export const useCustomTheme = () => {
  const { theme } = React.useContext(CustomThemeContext);
  return theme;
};

export const Box = styled(MaterialBox)(({ style }: { style?: any }) => ({
  ...style,
}));

export const Text = styled(Typography)(({ style }: { style?: any }) => ({
  fontFamily: "Good Sans",
  ...style,
}));
