import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  PhoneMultiFactorGenerator,
  TotpMultiFactorGenerator,
} from "firebase/auth";

import { useNavigate } from "react-router-dom";
import { PATHS } from "../routes/paths";
import { MfaType } from "../utils/constants";
import LoadingScreen from "./LoadingScreen";
import useAuth from "../hooks/useAuth";
import { useCustomTheme } from "../theme/CustomThemeProvider";

type SelectMFATypeProps = {
  open: boolean;
  onClose: () => void;
  setShowUnEnrollMfa: (mfaType: MfaType) => void;
};

export const SelectMFAType = ({
  open,
  onClose,
  setShowUnEnrollMfa,
}: SelectMFATypeProps) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const enrolledFactors = useMemo(
    () => user?.multiFactor?.enrolledFactors ?? [],
    [user?.multiFactor?.enrolledFactors]
  );
  const [loading, setLoading] = useState(true);
  const [isTotpMfaEnrolled, setIsTotpMfaEnrolled] = useState(false);
  const [isPhoneMfaEnrolled, setIsPhoneMfaEnrolled] = useState(false);
  const theme = useCustomTheme();
  const buttonText = isPhoneMfaEnrolled ? "unEnroll" : "enroll";
  const totpButtonText = isTotpMfaEnrolled ? "unEnroll" : "enroll";

  useEffect(() => {
    async function getUserData() {
      const currentEnrolledFactorIds = [];
      for (const enrolledFactor of enrolledFactors) {
        currentEnrolledFactorIds.push(enrolledFactor?.factorId);
      }

      setIsTotpMfaEnrolled(
        currentEnrolledFactorIds.includes(TotpMultiFactorGenerator.FACTOR_ID)
      );
      setIsPhoneMfaEnrolled(
        currentEnrolledFactorIds.includes(PhoneMultiFactorGenerator.FACTOR_ID)
      );
      setLoading(false);
    }

    getUserData();
  }, [enrolledFactors]);

  const onPhoneAuthentication = () => {
    if (buttonText === "enroll") {
      navigate(PATHS.accountMFA);
      return;
    }

    setShowUnEnrollMfa(MfaType.PHONE);
  };

  const onTotpMfa = async () => {
    if (totpButtonText === "enroll") {
      navigate(PATHS.accountTOTP);
      return;
    }

    setShowUnEnrollMfa(MfaType.TOTP);
  };

  const renderLoading = () => {
    return (
      <Box sx={{ mt: 5, mb: 5 }}>
        <LoadingScreen />
      </Box>
    );
  };
  const renderContent = () => {
    return (
      <Box alignItems={"flex-end"} justifyContent={"flex-end"}>
        <Box
          sx={{ mt: 2 }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            color={theme.styles.text["text-primary"]}
            variant={"body1 - regular  (0%)"}
          >
            Phone Authentication
          </Typography>
          <Button
            disabled={isTotpMfaEnrolled}
            color={isPhoneMfaEnrolled ? "error" : "inherit"}
            variant="outlined"
            onClick={onPhoneAuthentication}
          >
            {buttonText}
          </Button>
        </Box>

        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ mt: 2 }}
        >
          <Typography>TOTP Authentication (Authenticator app)</Typography>
          <Button
            disabled={isPhoneMfaEnrolled}
            color={isTotpMfaEnrolled ? "error" : "inherit"}
            variant="outlined"
            onClick={onTotpMfa}
          >
            {totpButtonText}
          </Button>
        </Box>
      </Box>
    );
  };
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "4px !important",
          backgroundColor: theme.styles.background["bg-primary"],
        },
      }}
      fullWidth
      maxWidth={"sm"}
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        style={{
          fontWeight: 700,
          fontSize: 24,
          fontFamily: "Good Sans",
          color: theme.styles.text["text-primary"],
        }}
      >
        Enroll/UnEnroll Multi-Factor Authentication
      </DialogTitle>
      <DialogContent>
        {loading && renderLoading()}
        {!loading && renderContent()}
      </DialogContent>
    </Dialog>
  );
};
