import { lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "layouts/dashboard";

// guards
import GuestGuard from "guards/GuestGuard";
import AuthGuard from "guards/AuthGuard";

// components
import LoadingScreen from "components/LoadingScreen";
import Page404 from "pages/Page404";
import { PATHS } from "./paths";
import RoleBasedGuard from "../guards/RoleBasedGuard";
import { CORPORATE_DASH_ADMINS } from "../utils/constants";
import { EnrollMFAPage } from "../pages/authentication/EnrollMFAPage";
import EmailVerificationGuard from "../guards/EmailVerificationGuard";
import VerifyEmailScreen from "../components/authentication/VerifyEmailScreen";
import { EnrollTOTP } from "../pages/authentication/EnrollTOTP";

// ----------------------------------------------------------------------
const Loadable = (Component: React.ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: PATHS.root,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={CORPORATE_DASH_ADMINS}>
              <UsageInsights />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    {
      path: PATHS.usageInsights,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={CORPORATE_DASH_ADMINS}>
              <UsageInsights />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    {
      path: PATHS.wellbeingInsights,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={CORPORATE_DASH_ADMINS}>
              <WellbeingInsights />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    {
      path: PATHS.viewDetail,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={CORPORATE_DASH_ADMINS}>
              <KeyWellbeingMetrics />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    {
      path: PATHS.careExperience,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={CORPORATE_DASH_ADMINS}>
              <CareExperience />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    {
      path: PATHS.resources,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={CORPORATE_DASH_ADMINS}>
              <Resources />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    {
      path: PATHS.trainingPrograms,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={CORPORATE_DASH_ADMINS}>
              <TrainingPrograms />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    {
      path: PATHS.accountMFA,
      element: (
        <AuthGuard>
          <EnrollMFAPage />
        </AuthGuard>
      ),
    },
    {
      path: PATHS.accountTOTP,
      element: (
        <AuthGuard>
          <EmailVerificationGuard>
            <EnrollTOTP />
          </EmailVerificationGuard>
        </AuthGuard>
      ),
    },
    {
      path: PATHS.verifyEmail,
      element: <VerifyEmailScreen />,
    },
    {
      path: PATHS.login,
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },

    {
      path: PATHS.resetPassword,
      element: (
        <GuestGuard>
          <ResetPassword />
        </GuestGuard>
      ),
    },
    // Make sure to render 404 pages as outlet of the main layout
    {
      path: "*",
      element: <Page404 />,
    },
    // This will simply cause "/404" to be rendered which is a
    // child route in "/". In other words: the causes the 404 to
    // be rendered as outlet in our main layout.
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import("pages/authentication/Login")));
const ResetPassword = Loadable(
  lazy(() => import("pages/authentication/ResetPassword"))
);

const UsageInsights = Loadable(lazy(() => import("pages/usage-insights")));
const WellbeingInsights = Loadable(
  lazy(() => import("pages/wellbeing-insights"))
);
const KeyWellbeingMetrics = Loadable(
  lazy(() => import("pages/key-wellbeing-metrics"))
);

const CareExperience = Loadable(lazy(() => import("pages/care-experience")));
const Resources = Loadable(lazy(() => import("pages/resources")));
const TrainingPrograms = Loadable(
  lazy(() => import("pages/training-programs"))
);
