import React, { useState, ReactNode } from "react";
import { Icon } from "@iconify/react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItemButtonProps,
} from "@mui/material";
import { Text, useCustomTheme } from "../theme/CustomThemeProvider";
import NavItemIcon from "./NavItemIcon";

// ----------------------------------------------------------------------

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(() => ({
  height: 48,
  position: "relative",
  marginLeft: "16px",
  marginRight: "16px",
  paddingLeft: "16px",
  borderRadius: "2px",
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 36,
  height: 36,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

type NavItemProps = {
  title: string;
  path: string;
  icon: string;
  info?: JSX.Element;
  selectedColor: string;
  children?: {
    title: string;
    path: string;
  }[];
};

function NavItem({
  item,
  isShow,
}: {
  item: NavItemProps;
  isShow?: boolean | undefined;
}) {
  const { pathname } = useLocation();
  const theme = useCustomTheme();
  const { title, path, icon, selectedColor, info, children } = item;
  const isActiveRoot = path
    ? !!matchPath({ path, end: false }, pathname)
    : false;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: theme.styles.text["text-primary"],
    variant: "body1 - regular  (0%)",
    bgcolor: theme.styles.background["bg-tertiary"],
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <NavItemIcon
            iconName={icon}
            isActiveRoot={isActiveRoot}
            selectedColor={selectedColor}
          />

          {isShow && (
            <>
              <Text
                sx={{ ml: 2 }}
                variant={"body1 - regular  (0%)"}
                color={theme.styles.text["text-primary"]}
              >
                {title}
              </Text>
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((childItem) => {
                const isActiveSub = childItem.path
                  ? !!matchPath({ path: childItem.path, end: false }, pathname)
                  : false;

                return (
                  <ListItemStyle
                    key={childItem.title}
                    component={RouterLink}
                    to={childItem.path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: "flex",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          bgcolor: "text.disabled",
                          transition: (transitionTheme) =>
                            transitionTheme.transitions.create("transform"),
                          ...(isActiveSub && {
                            transform: "scale(2)",
                            bgcolor: "primary.main",
                          }),
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={childItem.title} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <NavItemIcon
        iconName={icon}
        isActiveRoot={isActiveRoot}
        selectedColor={selectedColor}
      />
      {isShow && (
        <>
          <ListItemText
            sx={{ ml: 2 }}
            color={theme.styles.text["text-primary"]}
            disableTypography
            primary={title}
          />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

export default NavItem;
