import { IconProps } from "../NavItemIcon";

export default function Resources({ color, backgroundColor }: IconProps) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 7.00195C6.89543 7.00195 6 7.89738 6 9.00195V24.002C6 25.1065 6.89543 26.002 8 26.002H15C15.2652 26.002 15.5196 26.1073 15.7071 26.2948C15.8946 26.4824 16 26.7367 16 27.002C16 28.1065 16.8954 29.002 18 29.002C19.1046 29.002 20 28.1065 20 27.002V13.002C20 11.4107 19.3679 9.88453 18.2426 8.75931C17.1174 7.63409 15.5913 7.00195 14 7.00195H8Z"
        fill={color}
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 9.00195C30 7.89738 29.1046 7.00195 28 7.00195H22C20.4087 7.00195 18.8826 7.63409 17.7574 8.75931C16.6321 9.88453 16 11.4107 16 13.002V27.002C16 28.1065 16.8954 29.002 18 29.002C19.1046 29.002 20 28.1065 20 27.002C20 26.7367 20.1054 26.4824 20.2929 26.2948C20.4804 26.1073 20.7348 26.002 21 26.002H28C29.1046 26.002 30 25.1065 30 24.002V9.00195Z"
        fill={color}
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 9.00195H14C15.0609 9.00195 16.0783 9.42338 16.8284 10.1735C17.5786 10.9237 18 11.9411 18 13.002V27.002C18 26.2063 17.6839 25.4432 17.1213 24.8806C16.5587 24.318 15.7956 24.002 15 24.002H8V9.00195Z"
        stroke={backgroundColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 9.00195H22C20.9391 9.00195 19.9217 9.42338 19.1716 10.1735C18.4214 10.9237 18 11.9411 18 13.002V27.002C18 26.2063 18.3161 25.4432 18.8787 24.8806C19.4413 24.318 20.2044 24.002 21 24.002H28V9.00195Z"
        stroke={backgroundColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
