import { useEffect, useRef, useState } from "react";
import firebase from "firebase/compat/app";
import { FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { useMultiFactorLogin } from "hooks/useMultiFactorLogin";
import { PATHS } from "routes/paths";
import { isGoogleApiError } from "utils/isGoogleApiError";
import {
  TwoFactorVerificationForm,
  VerificationCodeFormValues,
} from "./TwoFactorVerificationForm";
import { MFAStep } from "../../../@types/authentication";
import { UnknownError } from "../../../@types/settings";
import { styled } from "@mui/material/styles";
import { ThemeProps } from "../../../theme/CustomThemeProvider";
import AuthLayout from "../../../layouts/AuthLayout";

const Container = styled(Box)<ThemeProps>(({ theme }) => ({
  backgroundColor: theme.styles.colors.white.white,
  padding: theme.spacingVal.xxxl,
  overflow: "hidden",
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
}));

const Title = styled(Typography)<ThemeProps>(({ theme }) => ({
  color: theme.styles.text["text-black"],
  textAlign: "center",
  marginBottom: 40,
}));

export function MFALogin({
  resolver,
  selectedIndex = 0,
}: {
  resolver: firebase.auth.MultiFactorResolver;
  selectedIndex: number;
}) {
  const navigate = useNavigate();
  const { requestAuthenticationCode, resolveSignIn } =
    useMultiFactorLogin(resolver);
  const selectedHint = resolver.hints[selectedIndex];

  const [step, setStep] = useState<MFAStep>(MFAStep.VERIFY);
  const reCaptchaVerifier = useRef<firebase.auth.RecaptchaVerifier | null>(
    null
  );

  const onSubmit = async (
    values: VerificationCodeFormValues,
    helpers: FormikHelpers<VerificationCodeFormValues>
  ) => {
    const { verificationCode } = values;
    try {
      await resolveSignIn(verificationCode);
      setStep(MFAStep.LOGGING_IN);
      navigate(PATHS.root);
    } catch (error: UnknownError) {
      if (isGoogleApiError(error)) {
        if (error.code === "auth/invalid-verification-code") {
          helpers.setFieldError("verificationCode", "invalid");
          return;
        }
      }

      console.error(error);
      setStep(MFAStep.ERROR);
    }
  };

  useEffect(() => {
    if (reCaptchaVerifier?.current !== null) {
      return;
    }

    reCaptchaVerifier.current = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container-id",
      {
        size: "invisible",
      }
    );
    reCaptchaVerifier.current.render().then(() => {
      if (reCaptchaVerifier.current) {
        // we are just making TS happy here
        requestAuthenticationCode(reCaptchaVerifier.current, selectedHint);
      }
    });
  }, [selectedHint, requestAuthenticationCode]);

  if (step === MFAStep.LOGGING_IN || step === MFAStep.ERROR) {
    let title = "Success";
    if (step === MFAStep.ERROR) {
      title = "An error occurred";
    }

    let message = "Logging you in";
    if (step === MFAStep.ERROR) {
      message =
        "An error occurred. Try again or contact support if the issue persists";
    }
    return (
      <>
        <AuthLayout headTitle={"Complete Sign-in"}>
          <Container>
            <Title variant="h4 - medium (-1%)">{title}</Title>
            <Typography textAlign="center">{message}</Typography>
          </Container>
        </AuthLayout>
      </>
    );
  }

  const onResendRequested = () => {
    if (reCaptchaVerifier.current) {
      requestAuthenticationCode(reCaptchaVerifier.current, selectedHint);
    } else {
      const error = new Error(
        "Authentication code was requested, but the reCaptcha is not initialized"
      );
      console.warn(error);
    }
  };

  return (
    <>
      <AuthLayout headTitle={"Complete Sign-in"}>
        <Container>
          <Title variant="h4 - medium (-1%)">Verify it's you</Title>
          <TwoFactorVerificationForm
            hint={`Please enter the code we sent to ${selectedHint.displayName}`}
            onSubmit={onSubmit}
            onResendRequested={onResendRequested}
          />
        </Container>
      </AuthLayout>
    </>
  );
}
