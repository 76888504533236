import { CustomTheme } from "../types";

// ----------------------------------------------------------------------

export default function Input(theme: CustomTheme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "& svg": { color: theme.styles.text["text-disabled"] },
          },
        },
        input: {
          "&::placeholder": {
            opacity: 1,
            color: theme.styles.text["text-secondary"],
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:before": {
            borderBottomColor: theme.styles.stroke["stroke-primary"],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.styles.background["bg-primary"],
          "&:hover": {
            backgroundColor: theme.styles.background["bg-primary"],
          },
          "&.Mui-focused": {
            backgroundColor: theme.styles.background["bg-primary"],
          },
          "&.Mui-disabled": {
            backgroundColor: theme.styles.background["bg-disabled"],
          },
        },
        underline: {
          "&:before": {
            borderBottomColor: theme.styles.stroke["stroke-primary"],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "4px",
            borderColor: theme.styles.stroke["stroke-primary"],
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.styles.stroke["stroke-primary"],
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.styles.stroke["stroke-primary"],
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "4px",
              borderColor: theme.styles.stroke["stroke-secondary"],
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.styles.text["text-secondary"],
          "&.Mui-focused": {
            color: theme.styles.text["text-primary"],
          },
          "&.Mui-hover": {
            color: theme.styles.text["text-primary"],
          },
          "&.Mui-disabled": {
            color: theme.styles.text["text-disabled"],
          },
        },
      },
    },
  };
}
