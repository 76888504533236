/* eslint-disable @typescript-eslint/no-shadow */
import { ReactNode, useEffect, useReducer, useState } from "react";
import { getGetTherapist, validateAdmin } from "../utils/firebase";

import { forceRefreshClaims } from "../utils/firebase/permissionsUtils";
import * as CloudFunctions from "utils/firebase/cloudFunctions";
import firebase from "firebase/compat/app";
import { firebaseAuth } from "contexts/FirebaseContext";
import * as InvitationCodeService from "services/InvitationCodeService";
// @types
import {
  ActionMap,
  AuthState,
  AuthUser,
  Roles,
} from "../@types/authentication";
import { AuthContext } from "./FirebaseContext";
import { ProviderAccessToken } from "../@types/audit-logs/log";
import { hasValidPermissions } from "../utils/helpers";
import { ROLES } from "../utils/constants";
import { GoogleLoginResponse } from "react-google-login";
import mixpanel from "mixpanel-browser";
import { persistor } from "redux/store";
import { KYAN_COOKIE_CONSENT_KEY } from "components/CookieBanner";
import * as Sentry from "@sentry/react";
import { UnknownError } from "../@types/settings";

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isTherapist: false,
  user: null,
  roles: {},
  checkedIn: false,
  checkInTime: undefined,
  checkOutTime: undefined,
  lastEventAt: undefined,
  providerAccessToken: { uid: "", accessToken: "" },
  registeredMFA: true, // We start on true to avoid showing dialogs
  shouldVerifyEmail: false,
  setShouldVerifyEmail(shouldVerifyEmail: boolean): void {},
};

enum Types {
  Initial = "INITIALISE",
  ToggleActivity = "TOGGLE_ACTIVITY",
  UpdateLastEvent = "UPDATE_LAST_EVENT",
}
type FirebaseActions =
  ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    roles?: Roles;
    registeredMFA: boolean;
    therapistId?: string;
    isTherapist: boolean;
    providerAccessToken?: ProviderAccessToken;
  };
  [Types.ToggleActivity]: {
    checkedIn: boolean;
    checkInTime?: Date;
    checkOutTime?: Date;
    lastEventAt?: Date;
  };
  [Types.UpdateLastEvent]: {
    lastEventAt: Date;
  };
};

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === "INITIALISE") {
    const {
      roles,
      therapistId,
      isAuthenticated,
      user,
      providerAccessToken,
      isTherapist,
      registeredMFA,
    } = action.payload;

    const updatedAccToken = {
      uid: "",
      accessToken: "",
    };
    if (providerAccessToken?.uid && providerAccessToken?.accessToken) {
      Object.assign(updatedAccToken, providerAccessToken);
    } else if (
      !providerAccessToken?.accessToken &&
      state.providerAccessToken?.accessToken &&
      (user?.id === state.providerAccessToken?.uid ||
        user?.uid === state?.providerAccessToken?.uid ||
        (!user?.id && !user?.uid))
    ) {
      Object.assign(updatedAccToken, state.providerAccessToken);
    }

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      roles,
      therapistId,
      isTherapist,
      providerAccessToken: updatedAccToken,
      registeredMFA,
    };
  }
  if (action.type === "TOGGLE_ACTIVITY") {
    const { checkedIn, checkInTime, checkOutTime, lastEventAt } =
      action.payload;

    return {
      ...state,
      lastEventAt,
      checkedIn,
      checkInTime,
      checkOutTime,
    };
  }
  if (action.type === "UPDATE_LAST_EVENT") {
    const { lastEventAt } = action.payload;

    return {
      ...state,
      lastEventAt,
    };
  }

  return state;
};
export function AuthProvider({ children }: { children: ReactNode }) {
  const [profile, setProfile] = useState<
    firebase.firestore.DocumentData | undefined
  >();

  // in the frontend to handle proper UI switching between verifyEmail and onboarding
  // this state is kept.
  const [shouldVerifyEmail, setShouldVerifyEmail] = useState(false);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [showLoginSuccessMessage, setShowLoginSuccessMessage] =
    useState<boolean>(false);
  const [attemptingLogin, setAttemptingLogin] = useState<boolean>(false);

  useEffect(
    () =>
      firebaseAuth().onAuthStateChanged(async (user) => {
        try {
          if (user?.uid) {
            user = { ...user, uid: user?.uid };
            const { data: roles } = await validateAdmin(user?.uid)
              .then((res) => res)
              .catch((err) => {
                throw Error(
                  err?.message || "something went wrong while validating admin"
                );
              });

            setProfile(user);

            // At this point the user has succesfully logged in.
            const invitationCode =
              await InvitationCodeService.getMyInvitationCode(user.uid);

            if (user.uid) {
              Sentry.setUser({ id: user?.uid });
              Sentry.setTag("invitationCode", invitationCode.code);
              mixpanel.people.set("invitationCode", invitationCode.code);
            }

            // Send showLoginSuccessMessage to tell subscribing components to
            // show success message. We have to use this approach because
            // firebase.auth().onAuthStateChanged is completely detached from any
            // of our login methods. This function fires whenever firebase decides
            // the user auth state has changed.
            //Added attemptingLogin check because onAuthStateChanged will also fire when the use first visits the site
            if (attemptingLogin) {
              setShowLoginSuccessMessage(true);
              setAttemptingLogin(false);

              //reset for next login attempt
              setShowLoginSuccessMessage(false);
            }

            // For older accounts. My assumption is that some older accounts
            // rely on chat custom claims instead of invitation code permissions
            // Checking to see if the user has chat custom claims first
            const hasChatCustomClaims = hasValidPermissions(
              roles?.usrRoles,
              Object.values(ROLES)
            );

            const hasChatEnabledOnInvitationCode =
              invitationCode.chat?.enabledChat || false;

            if (hasChatCustomClaims || hasChatEnabledOnInvitationCode) {
              const therapist = await getGetTherapist(user.uid)
                .get()
                .then((snap) => snap?.docs?.[0]?.data());

              dispatch({
                type: Types.Initial,
                payload: {
                  isAuthenticated: true,
                  user,
                  roles,
                  isTherapist: therapist ? true : false,
                  therapistId: therapist?.id,
                  registeredMFA: user?.multiFactor.enrolledFactors?.length > 0,
                },
              });
            } else {
              dispatch({
                type: Types.Initial,
                payload: {
                  isAuthenticated: true,
                  isTherapist: false,
                  registeredMFA: false,
                  user,
                  roles,
                },
              });
            }
          } else {
            throw Error("unable to authorize.");
          }
        } catch (error: UnknownError) {
          setProfile(undefined);
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              isTherapist: false,
              registeredMFA: false,
            },
          });
        }
      }), // eslint-disable-next-line
    [dispatch, attemptingLogin, setShowLoginSuccessMessage, setAttemptingLogin]
  );

  useEffect(() => {
    let unListen = () => {};
    if (profile?.uid) {
      unListen = forceRefreshClaims(profile?.uid);
    }
    return unListen;
  }, [profile]);

  const login = async (email: string, password: string) => {
    await firebaseAuth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

    return firebaseAuth().signInWithEmailAndPassword(email, password);
  };

  const loginWithGoogle = async (googleUser: GoogleLoginResponse) => {
    try {
      setAttemptingLogin(true);

      //Before we trying to sign into google, check to see the user exists in our DB. If we don't do this, google will create a new user and sign them in automatically. They won't have permission to do anything, but still not ideal
      const { data: { userExists = false } = {} } =
        await CloudFunctions.validateGoogleUser({
          googleAccessToken: googleUser.accessToken,
        });

      if (!userExists) {
        setAttemptingLogin(false);

        return {
          additionalUserInfo: null,
          credential: null,
          operationType: null,
          user: null,
        };
      }

      const credential = firebaseAuth.GoogleAuthProvider.credential(
        null,
        googleUser.accessToken
      );

      await firebaseAuth().setPersistence(
        firebase.auth.Auth.Persistence.SESSION
      );

      const loginRes = await firebaseAuth().signInWithCredential(credential);

      const accToken: string | undefined =
        // @ts-ignore
        loginRes?.credential?.accessToken ??
        // @ts-ignore
        loginRes.credential?.toJSON()?.oauthAccessToken ??
        undefined;

      dispatch({
        type: Types.Initial,
        payload: {
          ...(state || {}),
          providerAccessToken: {
            uid: loginRes?.user?.uid ?? "",
            accessToken: accToken ?? "",
            //@ts-ignore
            apiKey: loginRes.user?.toJSON()?.apiKey,
          },
        },
      });

      return loginRes;
    } catch (error: UnknownError) {
      console.error(error);

      setAttemptingLogin(false);

      let payload: FirebaseAuthPayload[Types.Initial] = {
        ...(state || {}),
      };

      dispatch({
        type: Types.Initial,
        payload: payload,
      });

      throw error;
    }
  };

  const toggleCheckedIn = (checkedIn: boolean, checkInTime: Date) => {
    dispatch({
      type: Types.ToggleActivity,
      payload: {
        checkedIn,
        ...(checkedIn ? { checkInTime } : { checkOutTime: new Date() }),
      },
    });
  };

  const updateLastEventTime = (charsTypes: number = 0) => {
    const { therapistId, checkInTime, checkedIn } = state;
    if (therapistId && checkInTime && checkedIn) {
      dispatch({
        type: Types.UpdateLastEvent,
        payload: {
          lastEventAt: new Date(),
        },
      });
    }
  };

  const register = (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) =>
    firebaseAuth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        firebase
          .firestore()
          .collection("users")
          .doc(res.user?.uid)
          .set({
            uid: res.user?.uid,
            email,
            displayName: `${firstName} ${lastName}`,
          });
      });

  const logout = async () => {
    await Promise.allSettled([firebaseAuth().signOut(), persistor.purge()]);
    mixpanel.reset();
    localStorage.removeItem(KYAN_COOKIE_CONSENT_KEY);
    Sentry.configureScope((scope) => scope.clear());

    //refresh page to clear state
    window.location.reload();
  };

  const resetPassword = async (email: string) => {
    await firebaseAuth().sendPasswordResetEmail(email);
  };

  // @ts-ignore
  const updateProfile = async () => {
    // await firebaseAuth().updateCurrentUser(user);
    // return true;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        user: { ...(profile || {}) },
        // @ts-ignore
        roles: state?.roles,
        login,
        register,
        loginWithGoogle,
        logout,
        resetPassword,
        updateProfile,
        showLoginSuccessMessage,
        attemptingLogin,
        setAttemptingLogin,
        toggleCheckedIn,
        updateLastEventTime,
        shouldVerifyEmail,
        setShouldVerifyEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
