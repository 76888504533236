const font = {
  heading: {
    "h1 - medium (-2%)": {
      fontSize: 24,
      textDecoration: "none",
      fontFamily: "Good Sans-Medium",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: -0.48,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
    "h2 - bold  (-1%)": {
      fontSize: 20,
      textDecoration: "none",
      fontFamily: "Good Sans-Bold",
      fontWeight: 700,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: -0.2,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
    "h3-bold(-1%)": {
      fontSize: 16,
      textDecoration: "none",
      fontFamily: "Good Sans-Bold",
      fontWeight: 700,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: -0.16,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
    "h4 - medium (-1%)": {
      fontSize: 20,
      textDecoration: "none",
      fontFamily: "Good Sans-Medium",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: -0.2,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
    "h0 - bold (-3%)": {
      fontSize: 60,
      textDecoration: "none",
      fontFamily: "Good Sans-Bold",
      fontWeight: 700,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: -1.8,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
  },
  title: {
    "title1 - medium (-1%)": {
      fontSize: 18,
      textDecoration: "none",
      fontFamily: "Good Sans-Medium",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: -0.18,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
    "title2  - medium  (-1%)": {
      fontSize: 16,
      textDecoration: "none",
      fontFamily: "Good Sans-Medium",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: -0.16,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
    "title3 - bold  (-1%)": {
      fontSize: 16,
      textDecoration: "none",
      fontFamily: "Good Sans-Bold",
      fontWeight: 700,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: -0.16,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
  },
  body: {
    "body1 - regular  (0%)": {
      fontSize: 14,
      textDecoration: "none",
      fontFamily: "Good Sans",
      fontWeight: 400,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: 0,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
    "body2 - bold  (0%)": {
      fontSize: 14,
      textDecoration: "none",
      fontFamily: "Good Sans-Bold",
      fontWeight: 700,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: 0,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
    "body3 - medium  (0%)": {
      fontSize: 12,
      textDecoration: "none",
      fontFamily: "Good Sans-Medium",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: 0,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
    "body4 - regular  (0%)": {
      fontSize: 12,
      textDecoration: "none",
      fontFamily: "Good Sans",
      fontWeight: 400,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: 0,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
    "body5 - regular (0%)": {
      fontSize: 16,
      textDecoration: "none",
      fontFamily: "Good Sans",
      fontWeight: 400,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: 0,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
    "body6-regular(0%)": {
      fontSize: 14,
      textDecoration: "none",
      fontFamily: "Good Sans",
      fontWeight: 400,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: 0,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
    "caption - regular  (0%)": {
      fontSize: 10,
      textDecoration: "none",
      fontFamily: "Good Sans",
      fontWeight: 400,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: 0,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
  },
  label: {
    "label1 - medium  (2%)": {
      fontSize: 12,
      textDecoration: "none",
      fontFamily: "Good Sans-Medium",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: 0.24,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "uppercase",
    },
    "label2 - medium  (2%)": {
      fontSize: 10,
      textDecoration: "none",
      fontFamily: "Good Sans-Medium",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: 0.2,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "uppercase",
    },
    "label3 - regular (2%)": {
      fontSize: 8,
      textDecoration: "none",
      fontFamily: "Good Sans",
      fontWeight: 400,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: 0.16,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "uppercase",
    },
  },
  button: {
    "button1 - medium  (-1%)": {
      fontSize: 16,
      textDecoration: "none",
      fontFamily: "Good Sans-Medium",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: -0.16,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
    "button2 - medium  (0%)": {
      fontSize: 14,
      textDecoration: "none",
      fontFamily: "Good Sans-Medium",
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: 0,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
  },
  link: {
    "link 1 - regular": {
      fontSize: 14,
      textDecoration: "underline",
      fontFamily: "Good Sans",
      fontWeight: 400,
      fontStyle: "normal",
      fontStretch: "normal",
      letterSpacing: 0,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      textCase: "none",
    },
  },
};
export default font;
