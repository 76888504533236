import { createContext } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import "firebase/compat/auth";

// @types
import { FirebaseContextType } from "../@types/authentication";
//
import { firebaseConfig } from "../config";

// ----------------------------------------------------------------------

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
}

export const db = firebase.firestore();
export const storage = firebase.storage();
export const FieldValue = firebase.firestore.FieldValue;

//Be careful with this. There's a difference between firebaseAuth and firebaseAuth().
// auth is a namespace with various properties and methods - https://firebase.google.com/docs/reference/js/v8#firebase.auth
// firebaseAuth() Returns an instance of the auth service - https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth
// auth and firebaseAuth() are both used. Check the docs if you're not sure which to use.
export const firebaseAuth = firebase.auth;

const [firebaseApp] = firebase.apps;
export const firebaseFunctions = firebaseApp.functions("europe-west3");

//Run emulators if env variables are set
const emulators = process.env.REACT_APP_EMULATORS;

if (emulators) {
  //if env variable is set to 'all' run all, else user can set individual emulators
  const allEmulators = emulators.indexOf("all") > -1;

  if (allEmulators || emulators.indexOf("firestore") > -1) {
    console.log("db emulator running");

    //This setting is needed for cypress to use emulated db - https://stackoverflow.com/questions/59336720/cant-use-cypress-to-test-app-using-using-firestore-local-emulator
    db.settings({
      experimentalForceLongPolling: true,
    });

    console.log("firestore emulator running");
    db.useEmulator(
      "localhost",
      process.env.REACT_APP_FIRESTORE_EMULATOR_PORT
        ? parseInt(process.env.REACT_APP_FIRESTORE_EMULATOR_PORT)
        : 8080
    );
  }

  if (allEmulators || emulators.indexOf("storage") > -1) {
    console.log("storage emulator running");
    storage.useEmulator(
      "localhost",
      process.env.REACT_APP_STORAGE_EMULATOR_PORT
        ? parseInt(process.env.REACT_APP_STORAGE_EMULATOR_PORT)
        : 9199
    );
  }

  if (allEmulators || emulators.indexOf("functions") > -1) {
    console.log("functions emulator running");
    firebaseFunctions.useEmulator(
      "localhost",
      process.env.REACT_APP_STORAGE_FUNCTIONS_PORT
        ? parseInt(process.env.REACT_APP_STORAGE_FUNCTIONS_PORT)
        : 5001
    );
  }

  if (allEmulators || emulators.indexOf("auth") > -1) {
    console.log("auth emulator running");
    firebaseAuth().useEmulator(
      "http://localhost:" +
        (process.env.REACT_APP_AUTH_EMULATOR_PORT
          ? parseInt(process.env.REACT_APP_AUTH_EMULATOR_PORT)
          : 9099)
    );
  }
}

export const AuthContext = createContext<FirebaseContextType | null>(null);
