import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

// hooks
import useAuth from "hooks/useAuth";

// routes
import { PATHS } from "routes/paths";
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

/**
 * This guard checks whether the user is authenticated or not. If they are, it redirects them to the correct place. I.e:
 * - If they are a therapist, it redirects them to their chats
 * - If they are a user without an active chat, it redirects them to the counsellors page
 * - If they are a user with an active chat, it redirects them to the chat page
 */

export default function EmailVerificationGuard({ children }: GuestGuardProps) {
  const { user, shouldVerifyEmail } = useAuth();

  const emailVerification = user?.emailVerification;

  const hasVerified =
    // @ts-ignore
    user !== "loading" && String(emailVerification?.hasVerified) === "true";
  const shouldCheckEmailVerification =
    // @ts-ignore
    user !== "loading" &&
    user &&
    emailVerification &&
    typeof emailVerification?.hasVerified === "boolean";

  // @ts-ignore
  if (user === "loading") {
    return <LoadingScreen />;
  }

  if (shouldVerifyEmail || (shouldCheckEmailVerification && !hasVerified)) {
    return <Navigate to={PATHS.verifyEmail} />;
  }

  return <>{children}</>;
}
