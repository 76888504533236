// material
import { Box, BoxProps, List } from "@mui/material";

import { PATHS } from "routes/paths";
import NavItem from "components/NavItem";
import { useCustomTheme } from "../theme/CustomThemeProvider";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

type NavItemProps = {
  title: string;
  path: string;
  icon: string;
  selectedColor: string;
  info?: JSX.Element;
  children?: {
    title: string;
    path: string;
  }[];
};

export const getTrainingProgramsImage = (
  name: string,
  width?: number,
  height?: number
) => (
  <Box
    component={"img"}
    width={width ?? "100%"}
    height={height ?? "100%"}
    src={`/static/trainingPrograms/${name}.png`}
    alt={"Not found"}
  />
);

type NavItemType = {
  title: string;
  path: string;
  icon: string;
  selectedColor: string;
};

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined;
}

export default function NavSection({
  isShow = true,
  ...other
}: NavSectionProps) {
  const theme = useCustomTheme();
  let dashboardNavItems: Array<NavItemType> = [
    {
      title: "Usage Insights",
      path: PATHS.usageInsights,
      icon: "UsageInsights",
      selectedColor: theme.styles.colors.beige["beige-secondary"],
    },
    {
      title: "Well-being Insights",
      path: PATHS.wellbeingInsights,
      icon: "WellbeingInsights",
      selectedColor: theme.styles.colors["bottom nav"].me,
    },
    {
      title: "Care Experience",
      path: PATHS.careExperience,
      icon: "CareExperience",
      selectedColor: theme.styles.colors["bottom nav"].counselling,
    },
    {
      title: "Resources",
      path: PATHS.resources,
      icon: "Resources",
      selectedColor: theme.styles.colors["bottom nav"].library,
    },
  ];

  const navConfig = [
    {
      subheader: "dashboard",
      items: dashboardNavItems,
    },
  ];

  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {items.map((item: NavItemProps) => (
              <NavItem key={item.title} item={item} isShow={isShow} />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
