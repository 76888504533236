import { Encrypted } from "../@types/user";
import { useEffect, useContext, useState } from "react";
import { EThreeContext } from "contexts/EThreeContext";
import { UnknownError } from "../@types/settings";

export default function useDecrypt(
  cipher: Encrypted<string> | undefined,
  fallback: string | null
): string | null {
  const { eThree } = useContext(EThreeContext);
  const [cleartext, setCleartext] = useState<string | null>("");
  useEffect(() => {
    if (!cipher || !eThree) {
      setCleartext(fallback);
      return;
    }

    let isStillRunning = true;
    async function decrypt() {
      let decryptedData = fallback;
      try {
        decryptedData = await eThree.decrypt(cipher);
      } catch (error: UnknownError) {
        console.error(error);
      }

      if (isStillRunning) {
        setCleartext(decryptedData);
      }
    }

    decrypt();

    return () => {
      isStillRunning = false;
    };
  }, [eThree, cipher, fallback]);

  return cleartext;
}
