import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export function ButtonLink({
  aTag,
  to,
  title,
}: {
  aTag?: boolean;
  to: string;
  title: string;
}) {
  // Avoid repeating yourself
  const InnerButton = () => (
    <LoadingButton
      sx={{ width: "100%" }}
      variant="contained"
      size={"large"}
      disabled={false}
    >
      {title}
    </LoadingButton>
  );

  return (
    <Typography marginTop="32px" textAlign="center">
      {aTag && (
        <a style={{ textDecoration: "none" }} href={to}>
          <InnerButton />
        </a>
      )}
      <Link style={{ textDecoration: "none" }} to={to}>
        {!aTag && <InnerButton />}
      </Link>
    </Typography>
  );
}
