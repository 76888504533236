import { Box } from "@mui/material";
import { Text, useCustomTheme } from "../theme/CustomThemeProvider";

const year = new Date().getFullYear();

export default function TermsFooter() {
  const theme = useCustomTheme();

  return (
    <Box
      mb={1}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Text
        variant="body5 - regular (0%)"
        color={theme.styles.text["text-secondary"]}
      >
        ©{year} Kyan Health AG
      </Text>
    </Box>
  );
}
