import { Box, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "routes/paths";
import { MultiFactorResolver, TotpMultiFactorGenerator } from "firebase/auth";
import {
  TwoFactorVerificationForm,
  VerificationCodeFormValues,
} from "../../components/authentication/login/TwoFactorVerificationForm";
import { MFAStep } from "../../@types/authentication";
import { isGoogleApiError } from "../../utils/isGoogleApiError";
import { UnknownError } from "../../@types/settings";
import { styled } from "@mui/material/styles";
import { ThemeProps } from "../../theme/CustomThemeProvider";
import AuthLayout from "../../layouts/AuthLayout";

const Container = styled(Box)<ThemeProps>(({ theme }) => ({
  backgroundColor: theme.styles.colors.white.white,
  padding: theme.spacingVal.xxxl,
  overflow: "hidden",
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
}));

const Title = styled(Typography)<ThemeProps>(({ theme }) => ({
  color: theme.styles.text["text-black"],
  textAlign: "center",
  marginBottom: 40,
}));

export function TOTPLogin({
  mfaResolver,
  selectedIndex,
}: {
  mfaResolver: MultiFactorResolver;
  selectedIndex: number;
}) {
  const [step, setStep] = useState<MFAStep>(MFAStep.VERIFY);

  const navigate = useNavigate();

  const onSubmit = async (
    values: VerificationCodeFormValues,
    helpers: FormikHelpers<VerificationCodeFormValues>
  ) => {
    const { verificationCode: otpFromAuthenticator } = values;

    try {
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
        mfaResolver.hints[selectedIndex].uid,
        otpFromAuthenticator
      );

      await mfaResolver.resolveSignIn(multiFactorAssertion);
      setStep(MFAStep.LOGGING_IN);
      navigate(PATHS.usageInsights);
    } catch (error: UnknownError) {
      if (isGoogleApiError(error)) {
        if (error.code === "auth/invalid-verification-code") {
          helpers.setFieldError(
            "verificationCode",
            "The code you entered doesn't match"
          );
          return;
        }
      }

      console.error(error);
      setStep(MFAStep.ERROR);
    }
  };

  if ([MFAStep.LOGGING_IN, MFAStep.ERROR].includes(step)) {
    let title = "Success";
    let message = "Logging you in";
    if (step === MFAStep.ERROR) {
      title = "An error occurred";
      message =
        "An error occurred. Try again or contact support if the issue persists";
    }

    return (
      <>
        <AuthLayout headTitle={"Complete Sign-in"}>
          <Container>
            <Title variant="h4 - medium (-1%)">{title}</Title>
            <Typography textAlign="center">{message}</Typography>
          </Container>
        </AuthLayout>
      </>
    );
  }

  return (
    <>
      <AuthLayout headTitle={"Complete Sign-in"}>
        <Container>
          <Title variant="h4 - medium (-1%)">Verify it's you</Title>
          <TwoFactorVerificationForm
            hideResendCodeButton={true}
            hint={"Please enter the code displayed on your authenticator app"}
            onSubmit={onSubmit}
            onResendRequested={() => {}}
          />
        </Container>
      </AuthLayout>
    </>
  );
}
