import { useEffect, useRef, useState } from "react";
import firebase from "firebase/compat/app";
import {
  MultiFactorResolver,
  PhoneMultiFactorGenerator,
  TotpMultiFactorGenerator,
} from "firebase/auth";

import { Box } from "@mui/material";

// components
import { LoginForm } from "components/authentication/login";
import GoogleSignInButton from "components/GoogleSignInButton";
import { MFALogin } from "../../components/authentication/login/MFALogin";
import { TOTPLogin } from "./TOTPLogin";
import AuthLayout from "../../layouts/AuthLayout";
import {
  Text,
  ThemeProps,
  useCustomTheme,
} from "../../theme/CustomThemeProvider";
import { styled } from "@mui/material/styles";

enum Mode {
  MFA = "mfa",
  LOG_IN = "login",
}

const Container = styled(Box)<ThemeProps>(({ theme }) => ({
  backgroundColor: theme.styles.colors.white.white,
  padding: theme.spacingVal.xxxl,
  overflow: "hidden",
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
}));

const Login = () => {
  const [mode, setMode] = useState<Mode>(Mode.LOG_IN);
  const theme = useCustomTheme();

  const [selectedMFA, setSelectedMFA] = useState<string | null>(null);
  const mfaResolver = useRef<
    MultiFactorResolver | firebase.auth.MultiFactorResolver | undefined
  >();

  useEffect(() => {
    if (mode === "mfa") {
      setSelectedMFA(mfaResolver.current?.hints?.[0]?.factorId ?? null);
    }
  }, [mode]);

  const getSelectedMFAIndex = () => {
    return mfaResolver.current?.hints?.findIndex(
      (mfaVal) => mfaVal.factorId === selectedMFA
    );
  };

  if (mode === "mfa" && selectedMFA === PhoneMultiFactorGenerator.FACTOR_ID) {
    return (
      <MFALogin
        resolver={mfaResolver.current as firebase.auth.MultiFactorResolver}
        selectedIndex={getSelectedMFAIndex() ?? 0}
      />
    );
  }

  if (mode === "mfa" && selectedMFA === TotpMultiFactorGenerator.FACTOR_ID) {
    return (
      <TOTPLogin
        mfaResolver={mfaResolver.current as MultiFactorResolver}
        selectedIndex={getSelectedMFAIndex() ?? 0}
      />
    );
  }

  return (
    <AuthLayout headTitle={"Sign In"}>
      <Container>
        <Text
          sx={{ mb: 5 }}
          variant="h5 - medium (-1%)"
          gutterBottom
          aria-label="Login title"
          textAlign={"center"}
          color={theme.styles.text["text-black"]}
        >
          Sign in to Kyan
        </Text>

        <LoginForm
          onMfa={(resolver: firebase.auth.MultiFactorResolver) => {
            mfaResolver.current = resolver;
            setMode(Mode.MFA);
          }}
        />

        <GoogleSignInButton
          onMfa={(resolver: firebase.auth.MultiFactorResolver) => {
            mfaResolver.current = resolver;
            setMode(Mode.MFA);
          }}
        />
      </Container>
    </AuthLayout>
  );
};

export default Login;
