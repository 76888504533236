import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import KyanLogo from "components/icons/KyanLogo";

const SectionStyle = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  margin: theme.spacing(2, 0, 2, 2),
  padding: "0px 40px",
}));

const AuthenticationHeader = () => {
  return (
    <SectionStyle>
      <KyanLogo />
    </SectionStyle>
  );
};

export default AuthenticationHeader;
