// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles"; // hooks
import useAuth from "./hooks/useAuth";
// components
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import GoogleAnalytics from "./components/GoogleAnalytics";
import NotistackProvider from "./components/NotistackProvider";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import ThemeLocalization from "./components/ThemeLocalization";
import LoadingScreen, { ProgressBarStyle } from "./components/LoadingScreen";
import { AnalyticsProvider } from "contexts/AnalyticsContext";
import CookieBanner from "components/CookieBanner";
import { usePageViewTracking } from "hooks/usePageViewTracking";
import theme from "./chatTheme";
import { CountryFilterProvider } from "./contexts/CountryFilterContext";
import "print.css"; // Import the print stylesheet
import { CustomThemeProvider } from "./theme/CustomThemeProvider";

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();
  usePageViewTracking();

  return (
    <MuiThemeProvider theme={theme}>
      <CustomThemeProvider>
        <CssBaseline />
        <ThemeConfig>
          <ThemePrimaryColor>
            <ThemeLocalization>
              <AnalyticsProvider>
                <CountryFilterProvider>
                  <RtlLayout>
                    <NotistackProvider>
                      <GlobalStyles />
                      <ProgressBarStyle />
                      <ScrollToTop />
                      <GoogleAnalytics />
                      {isInitialized ? <Router /> : <LoadingScreen />}
                      <CookieBanner />
                    </NotistackProvider>
                  </RtlLayout>
                </CountryFilterProvider>
              </AnalyticsProvider>
            </ThemeLocalization>
          </ThemePrimaryColor>
        </ThemeConfig>
      </CustomThemeProvider>
    </MuiThemeProvider>
  );
}
