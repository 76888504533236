import { CustomTheme } from "../types";
import { typography, semantics, font, flattenedPrimitives } from "../tokens";

export const getBaseTheme = (isDarkThemeActive: boolean) => {
  const activeThemeSemantics = isDarkThemeActive
    ? semantics["dark-mode"]
    : semantics["light-mode"];

  const baseTheme: CustomTheme = {
    spacingVal: {
      sm: 4,
      m: 8,
      lg: 12,
      xl: 16,
      xxl: 24,
      xxxl: 32,
      xxxlg: 36,
      "40": 40,
      "48": 48,
      "60": 60,
      "10": 10,
      "1": 1,

      //DEPRICATED
      none: 0,
      xs: 4,
      s: 8,
      s1: 10,
      s2: 12,
      ml: 18,
      l: 24,
      l1: 27,
      l2: 30,
      xxxxl: 58,
      "20": 20,
      "56": 56,
      "64": 64,
      "68": 68,
      "72": 72,
      "80": 80,
      "88": 88,
      "96": 96,
    },
    borderRadii: {
      sm: 4,
      md: 8,
      lg: 12,
      xl: 16,
      xxl: 24,
      xxxl: 32,

      //DEPRICATED
      "5": 5,
      "16": 16,
      "8": 8,
      "37.5": 37.5,
      "32": 32,
      "60": 60,
      "53": 53,
      75: 75,
      none: 0,
      xs: 4,
      s: 8,
      m: 16,
      m1: 20,
      l: 24,
    },
    breakpoints: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
    colors: flattenedPrimitives,
    font,
    typography: typography,
    styles: activeThemeSemantics,
    darkModeStyles: semantics["dark-mode"],
  };

  return baseTheme;
};
