import React from "react";
import { CSSProperties } from "react";
import { useCustomTheme } from "../theme/CustomThemeProvider";
import UsageInsights from "./icons/UsageInsights";
import WellbeingInsights from "./icons/WellbeingInsights";
import Resources from "./icons/Resources";
import CareExperience from "./icons/CareExperience";

type RenderNavItemIconProps = {
  iconName: string;
  selectedColor: string;
  isActiveRoot: boolean;
};

export type IconProps = {
  color?: string;
  size?: number;
  style?: CSSProperties;
  backgroundColor?: string;
  dotBackgroundColor?: string;
  isSelected?: boolean;
};

const NavItemIcon = ({
  iconName,
  selectedColor,
  isActiveRoot,
}: RenderNavItemIconProps) => {
  const theme = useCustomTheme();
  const componentMap: { [key: string]: React.FC<IconProps> } = {
    UsageInsights,
    WellbeingInsights,
    Resources,
    CareExperience,
  };

  const ComponentToRender = componentMap[iconName];

  if (!ComponentToRender) {
    console.error(`Icon component for "${iconName}" not found.`);
    return null;
  }

  return (
    <ComponentToRender
      color={
        isActiveRoot ? selectedColor : theme.styles.background["bg-primary"]
      }
      backgroundColor={
        isActiveRoot
          ? theme.styles.icons["icon-black"]
          : theme.styles.icons["icon-secondary"]
      }
    />
  );
};

export default NavItemIcon;
