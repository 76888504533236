//DEPRECATED
const palette = {
  white: "#ffffff",
  semiWhite: "rgba(246,246,248,0.1)",
  gray: "rgb(230,230,234)",
  black: "#262632",
  blackI: "#2a2a32",
  blackIII: "#353541",
  secondaryBlack: "#353540",
  ternaryBlack: "#1F1F28",
  offWhite: "#F3F3F7",
  secondaryBlackText: "#D8D8D8",
  red: "#F35C5C",
  grey500: "#919399",

  blue100: "#f4f4f7",
  blue200: "#e8e8ef",
  blue300: "#d2d2df",
  blue400: "#a5a5bf",
  blue500: "#7a789f",
  blue600: "#4c4b7f",
  blue700: "#201e5f",
  blueDark: "#1F1E5F",
  blueDarkSemiTransparent: "rgba(31, 30, 95, 0.1)",
  blueDarkII: "#1F1E5F",
  darkRoyalBlue: "#0359FF",
  lightRoyalBlue: "#00C2FF",
  semiRoyalBlue: "#0093FD",
  blueI: "#67C8FF",
  blueII: "#6BB8C8",
  blueIII: "#90C9FA",
  blueIV: "#0094FF",
  blueV: "#76CEFF",
  purple: "#AAADFF",
  purpleI: "#B399FF",
  purpleII: "#4C466E",
  lightPurple: "#1F1E5F99",
  semiPurple: "rgba(31, 30, 95, 0.8)",

  orange100: "#fff7f7",
  orange200: "#feefef",
  orange300: "#fddede",
  orange400: "#fabebf",
  orange500: "#f89d9d",
  orange600: "#f57d7d",
  orange700: "#f35d5c",
  red700: "#F35C5C40",
  red100: "#FC7070",
  red200: "#CF485E",
  red300: "#F18A9B",
  red400: "#FF7B90",
  red500: "#FF6C83",
  red600: "#FF8080",

  grey200: "#E5E7EB",

  teal100: "#f8fffe",
  teal200: "#f1fefc",
  teal300: "#e3fcf9",
  teal400: "#c5faf4",
  teal500: "#a9f7ee",
  teal600: "#8df5e9",
  teal700: "#71f2e3",
  teal800: "#69DDD6",
  green: "#52CC3E",
  greenI: "#3BB627",
  greenII: "#91DE76",
  greenIII: "#5AD675",
  greenIV: "#75A783",
  greenV: "#B3D687",
  greenVI: "#5EB67C",
  greenVII: "#CCE6AC",
  greenVIII: "#A3DDB3",
  greenIX: "#C2E793",
  greenX: "#7DD285",
  greenXI: "#B4E88C",
  yellow: "#FFC56F",
  yellowI: "#FFD79B",
  yellowII: "#E67D5F",
  yellowIII: "#FFBF5F",
  yellowIV: "#EF8A6C",
  yellowV: "#EFAF9C",
  yellowVI: "#FFC063",
  yellowVII: "#FF9A3D",
  yellowVIII: "#FFD363",
  yellowIX: "#FFCA63",
  yellowX: "#FFAB8F",
  yellowXI: "#F5E077",
  yellowXII: "#FFEEA8",
  crayolaYellow: "#FFEC8A",
  reddish: "#E06377",
  star: "#FF9F0A",
  lightGreen: "#C9D7B1",
  lightYellow: "#FFEBCC",
  lightPink: "#F4D5D5",
  lightBlue: "#D1EEFE",
  lightBlueI: "#82DEF1",
  lightBlueII: "#D6D6FF",
  skyblue: "#7BD0FF",
  lightPurpleI: "#DFE0F9",
};

const primitives = {
  black: {
    "black-950": "#080808ff",
    "black-800": "#484848ff",
    "black-700": "#5f5f5fff",
    "black-600": "#767676ff",
    "black-500": "#8c8c8cff",
    "black-400": "#a3a3a3ff",
    "black-300": "#bababaff",
    "black-200": "#d1d1d1ff",
    "black-100": "#e8e8e8ff",
    "black-900": "#313131ff",
  },
  white: {
    white: "#ffffffff",
  },
  beige: {
    "beige-200": "#f2e7d9ff",
    "beige-100": "#f7f2e8ff",
    "beige-50": "#fdfcf9ff",
    "beige-300": "#e8dccfff",
    "beige-400": "#ded1c5ff",
    "beige-500": "#d4c5bbff",
    "beige-600": "#cabab1ff",
    "beige-700": "#aa9b92ff",
    "beige-800": "#8b7b72ff",
    "beige-900": "#6b5c53ff",
    "beige-950": "#4c3c34ff",
  },
  blue: {
    "blue-50": "#c7e9ffff",
    "blue-700": "#0098edff",
    "blue-100": "#a6deffff",
    "blue-600": "#05a7ffff",
    "blue-500": "#2cb3ffff",
    "blue-200": "#85d3ffff",
    "blue-300": "#64c8ffff",
    "blue-400": "#48bdffff",
    "blue-800": "#007ec9ff",
    "blue-900": "#0065a4ff",
    "blue-950": "#004a80ff",
  },
  green: {
    "green-100": "#cdf0b2ff",
    "green-200": "#b4e88cff",
    "green-300": "#a0e082ff",
    "green-400": "#8dd979ff",
    "green-500": "#77ce72ff",
    "green-600": "#5cc266ff",
    "green-700": "#37af5eff",
    "green-800": "#2f9550ff",
    "green-900": "#277b42ff",
    "green-50": "#e0f5cfff",
    "green-950": "#1e6134ff",
  },
  taupe: {
    "taupe-50": "#e8e1deff",
    "taupe-100": "#e1d8d2ff",
    "taupe-200": "#d9cec7ff",
    "taupe-300": "#d2c4bcff",
    "taupe-400": "#cabab1ff",
    "taupe-500": "#b5a59cff",
    "taupe-600": "#a09087ff",
    "taupe-700": "#8b7b72ff",
    "taupe-800": "#76665dff",
    "taupe-900": "#615148ff",
    "taupe-950": "#4c3c34ff",
  },
  brown: {
    "brown-50": "#e8d9c7ff",
    "brown-100": "#dfc9b2ff",
    "brown-200": "#d6b99eff",
    "brown-300": "#cda885ff",
    "brown-400": "#c4976dff",
    "brown-500": "#bb8654ff",
    "brown-600": "#ac6e48ff",
    "brown-700": "#9d563cff",
    "brown-800": "#8e4c36ff",
    "brown-900": "#7e4330ff",
    "brown-950": "#6f392aff",
  },
  red: {
    "red-50": "#ffcfc1ff",
    "red-100": "#ffb8a1ff",
    "red-200": "#ffa082ff",
    "red-300": "#f78c66ff",
    "red-400": "#f0794aff",
    "red-500": "#e9662eff",
    "red-600": "#e15212ff",
    "red-700": "#c9470eff",
    "red-800": "#b13b09ff",
    "red-900": "#993005ff",
    "red-950": "#812400ff",
  },
  orange: {
    "orange-50": "#ffdbc2ff",
    "orange-100": "#ffc89eff",
    "orange-200": "#ffb375ff",
    "orange-300": "#ffa051ff",
    "orange-400": "#ff9032ff",
    "orange-500": "#ff801eff",
    "orange-600": "#ff700aff",
    "orange-700": "#ef6706ff",
    "orange-800": "#d25a04ff",
    "orange-900": "#b34c02ff",
    "orange-950": "#943e00ff",
  },
  yellow: {
    "yellow-50": "#ffeec2ff",
    "yellow-100": "#ffe59eff",
    "yellow-400": "#f8bc1dff",
    "yellow-500": "#efb110ff",
    "yellow-200": "#fed76aff",
    "yellow-300": "#fec937ff",
    "yellow-350": "#FFCA63",
    "yellow-600": "#e5a603ff",
    "yellow-700": "#cc9402ff",
    "yellow-800": "#b28102ff",
    "yellow-900": "#996e01ff",
    "yellow-950": "#805c00ff",
  },
  moss: {
    "moss-50": "#e9e3beff",
    "moss-100": "#e2daa8ff",
    "moss-200": "#d7cb85ff",
    "moss-300": "#c9b95dff",
    "moss-400": "#b8a53cff",
    "moss-500": "#a6993cff",
    "moss-600": "#948c3bff",
    "moss-700": "#807e3cff",
    "moss-800": "#686d39ff",
    "moss-900": "#4f5b36ff",
    "moss-950": "#404a2cff",
  },
  emarald: {
    "emarald-50": "#d1f4e7ff",
    "emarald-100": "#b4ebd7ff",
    "emarald-200": "#96e2c6ff",
    "emarald-300": "#79d9b6ff",
    "emarald-400": "#4dd3a2ff",
    "emarald-500": "#2cca90ff",
    "emarald-600": "#0bc27fff",
    "emarald-700": "#0ab375ff",
    "emarald-800": "#099562ff",
    "emarald-900": "#077d52ff",
    "emarald-950": "#06603fff",
  },
  torquoise: {
    "turquoise-50": "#e1f5faff",
    "turquoise-100": "#c3ecf5ff",
    "turquoise-200": "#a6e2f0ff",
    "turquoise-300": "#88d9ebff",
    "turquoise-400": "#73d1e5ff",
    "turquoise-500": "#5ec9e0ff",
    "turquoise-600": "#4ac1dbff",
    "turquoise-700": "#3da7beff",
    "turquoise-800": "#308ea2ff",
    "turquoise-900": "#227486ff",
    "turquoise-950": "#155b6aff",
  },
  azure: {
    "azure-50": "#d9edffff",
    "azure-100": "#b2dbffff",
    "azure-200": "#91c9feff",
    "azure-300": "#70b7feff",
    "azure-600": "#0c81fcff",
    "azure-700": "#096eddff",
    "azure-800": "#065bbeff",
    "azure-900": "#03489eff",
    "azure-950": "#00357fff",
    "azure-400": "#4ea5fdff",
    "azure-500": "#2d93fdff",
  },
  voilet: {
    "violet-50": "#dddfffff",
    "violet-100": "#d5d7ffff",
    "violet-200": "#c7caffff",
    "violet-300": "#bbbdffff",
    "violet-400": "#aeb1ffff",
    "violet-500": "#9ea2fdff",
    "violet-600": "#8e93fbff",
    "violet-700": "#7c82f7ff",
    "violet-800": "#646ae2ff",
    "violet-1000": "#4a51c9ff",
    "violet-950": "#3d43afff",
  },
  fuchsia: {
    "fuchsia-50": "#f8d8fdff",
    "fuchsia-100": "#f6beefff",
    "fuchsia-200": "#f4a4e2ff",
    "fuchsia-300": "#f493d5ff",
    "fuchsia-400": "#f482c8ff",
    "fuchsia-500": "#eb6fbbff",
    "fuchsia-600": "#e35cafff",
    "fuchsia-700": "#c54a96ff",
    "fuchsia-800": "#a7387cff",
    "fuchsia-900": "#8a2663ff",
    "fuchsia-950": "#6c144aff",
  },
  pink: {
    "pink-50": "#fde3e7ff",
    "pink-100": "#fcd6dcff",
    "pink-200": "#fac1caff",
    "pink-300": "#fca0b3ff",
    "pink-400": "#fd809dff",
    "pink-500": "#ff6086ff",
    "pink-600": "#ef5479ff",
    "pink-700": "#df486cff",
    "pink-800": "#bf3052ff",
    "pink-900": "#9f1838ff",
    "pink-950": "#80001fff",
  },
  purple: {
    "purple-50": "#f3e0ffff",
    "purple-100": "#eed6ffff",
    "purple-200": "#e7c4ffff",
    "purple-300": "#e0b3ffff",
    "purple-400": "#d9a2feff",
    "purple-500": "#cf92f8ff",
    "purple-600": "#bb73ebff",
    "purple-700": "#9e5accff",
    "purple-800": "#8242adff",
    "purple-900": "#65298eff",
    "purple-950": "#571c7fff",
  },
};
export default primitives;

export const flattenedPrimitives = {
  "black-950": "#080808ff",
  "black-800": "#484848ff",
  "black-700": "#5f5f5fff",
  "black-600": "#767676ff",
  "black-500": "#8c8c8cff",
  "black-400": "#a3a3a3ff",
  "black-300": "#bababaff",
  "black-200": "#d1d1d1ff",
  "black-100": "#e8e8e8ff",
  "black-900": "#313131ff",
  white: "#ffffffff",
  "beige-200": "#f2e7d9ff",
  "beige-100": "#f7f2e8ff",
  "beige-50": "#fdfcf9ff",
  "beige-300": "#e8dccfff",
  "beige-400": "#ded1c5ff",
  "beige-500": "#d4c5bbff",
  "beige-600": "#cabab1ff",
  "beige-700": "#aa9b92ff",
  "beige-800": "#8b7b72ff",
  "beige-900": "#6b5c53ff",
  "beige-950": "#4c3c34ff",
  "blue-50": "#c7e9ffff",
  "blue-700": "#0098edff",
  "blue-100": "#a6deffff",
  "blue-600": "#05a7ffff",
  "blue-500": "#2cb3ffff",
  "blue-200": "#85d3ffff",
  "blue-300": "#64c8ffff",
  "blue-400": "#48bdffff",
  "blue-800": "#007ec9ff",
  "blue-900": "#0065a4ff",
  "blue-950": "#004a80ff",
  "green-100": "#cdf0b2ff",
  "green-200": "#b4e88cff",
  "green-300": "#a0e082ff",
  "green-400": "#8dd979ff",
  "green-500": "#77ce72ff",
  "green-600": "#5cc266ff",
  "green-700": "#37af5eff",
  "green-800": "#2f9550ff",
  "green-900": "#277b42ff",
  "green-50": "#e0f5cfff",
  "green-950": "#1e6134ff",
  "taupe-50": "#e8e1deff",
  "taupe-100": "#e1d8d2ff",
  "taupe-200": "#d9cec7ff",
  "taupe-300": "#d2c4bcff",
  "taupe-400": "#cabab1ff",
  "taupe-500": "#b5a59cff",
  "taupe-600": "#a09087ff",
  "taupe-700": "#8b7b72ff",
  "taupe-800": "#76665dff",
  "taupe-900": "#615148ff",
  "taupe-950": "#4c3c34ff",
  "brown-50": "#e8d9c7ff",
  "brown-100": "#dfc9b2ff",
  "brown-200": "#d6b99eff",
  "brown-300": "#cda885ff",
  "brown-400": "#c4976dff",
  "brown-500": "#bb8654ff",
  "brown-600": "#ac6e48ff",
  "brown-700": "#9d563cff",
  "brown-800": "#8e4c36ff",
  "brown-900": "#7e4330ff",
  "brown-950": "#6f392aff",
  "red-50": "#ffcfc1ff",
  "red-100": "#ffb8a1ff",
  "red-200": "#ffa082ff",
  "red-300": "#f78c66ff",
  "red-400": "#f0794aff",
  "red-500": "#e9662eff",
  "red-600": "#e15212ff",
  "red-700": "#c9470eff",
  "red-800": "#b13b09ff",
  "red-900": "#993005ff",
  "red-950": "#812400ff",
  "orange-50": "#ffdbc2ff",
  "orange-100": "#ffc89eff",
  "orange-200": "#ffb375ff",
  "orange-300": "#ffa051ff",
  "orange-400": "#ff9032ff",
  "orange-500": "#ff801eff",
  "orange-600": "#ff700aff",
  "orange-700": "#ef6706ff",
  "orange-800": "#d25a04ff",
  "orange-900": "#b34c02ff",
  "orange-950": "#943e00ff",
  "yellow-50": "#ffeec2ff",
  "yellow-100": "#ffe59eff",
  "yellow-400": "#f8bc1dff",
  "yellow-500": "#efb110ff",
  "yellow-200": "#fed76aff",
  "yellow-300": "#fec937ff",
  "yellow-600": "#e5a603ff",
  "yellow-700": "#cc9402ff",
  "yellow-800": "#b28102ff",
  "yellow-900": "#996e01ff",
  "yellow-950": "#805c00ff",
  "moss-50": "#e9e3beff",
  "moss-100": "#e2daa8ff",
  "moss-200": "#d7cb85ff",
  "moss-300": "#c9b95dff",
  "moss-400": "#b8a53cff",
  "moss-500": "#a6993cff",
  "moss-600": "#948c3bff",
  "moss-700": "#807e3cff",
  "moss-800": "#686d39ff",
  "moss-900": "#4f5b36ff",
  "moss-950": "#404a2cff",
  "emarald-50": "#d1f4e7ff",
  "emarald-100": "#b4ebd7ff",
  "emarald-200": "#96e2c6ff",
  "emarald-300": "#79d9b6ff",
  "emarald-400": "#4dd3a2ff",
  "emarald-500": "#2cca90ff",
  "emarald-600": "#0bc27fff",
  "emarald-700": "#0ab375ff",
  "emarald-800": "#099562ff",
  "emarald-900": "#077d52ff",
  "emarald-950": "#06603fff",
  "turquoise-50": "#e1f5faff",
  "turquoise-100": "#c3ecf5ff",
  "turquoise-200": "#a6e2f0ff",
  "turquoise-300": "#88d9ebff",
  "turquoise-400": "#73d1e5ff",
  "turquoise-500": "#5ec9e0ff",
  "turquoise-600": "#4ac1dbff",
  "turquoise-700": "#3da7beff",
  "turquoise-800": "#308ea2ff",
  "turquoise-900": "#227486ff",
  "turquoise-950": "#155b6aff",
  "azure-50": "#d9edffff",
  "azure-100": "#b2dbffff",
  "azure-200": "#91c9feff",
  "azure-300": "#70b7feff",
  "azure-600": "#0c81fcff",
  "azure-700": "#096eddff",
  "azure-800": "#065bbeff",
  "azure-900": "#03489eff",
  "azure-950": "#00357fff",
  "azure-400": "#4ea5fdff",
  "azure-500": "#2d93fdff",
  "violet-50": "#dddfffff",
  "violet-100": "#d5d7ffff",
  "violet-200": "#c7caffff",
  "violet-300": "#bbbdffff",
  "violet-400": "#aeb1ffff",
  "violet-500": "#9ea2fdff",
  "violet-600": "#8e93fbff",
  "violet-700": "#7c82f7ff",
  "violet-800": "#646ae2ff",
  "violet-1000": "#4a51c9ff",
  "violet-950": "#3d43afff",
  "fuchsia-50": "#f8d8fdff",
  "fuchsia-100": "#f6beefff",
  "fuchsia-200": "#f4a4e2ff",
  "fuchsia-300": "#f493d5ff",
  "fuchsia-400": "#f482c8ff",
  "fuchsia-500": "#eb6fbbff",
  "fuchsia-600": "#e35cafff",
  "fuchsia-700": "#c54a96ff",
  "fuchsia-800": "#a7387cff",
  "fuchsia-900": "#8a2663ff",
  "fuchsia-950": "#6c144aff",
  "pink-50": "#fde3e7ff",
  "pink-100": "#fcd6dcff",
  "pink-200": "#fac1caff",
  "pink-300": "#fca0b3ff",
  "pink-400": "#fd809dff",
  "pink-500": "#ff6086ff",
  "pink-600": "#ef5479ff",
  "pink-700": "#df486cff",
  "pink-800": "#bf3052ff",
  "pink-900": "#9f1838ff",
  "pink-950": "#80001fff",
  "purple-50": "#f3e0ffff",
  "purple-100": "#eed6ffff",
  "purple-200": "#e7c4ffff",
  "purple-300": "#e0b3ffff",
  "purple-400": "#d9a2feff",
  "purple-500": "#cf92f8ff",
  "purple-600": "#bb73ebff",
  "purple-700": "#9e5accff",
  "purple-800": "#8242adff",
  "purple-900": "#65298eff",
  "purple-950": "#571c7fff",

  //DEPRECATED
  semiWhite: "rgba(246,246,248,0.1)",
  gray: "rgb(230,230,234)",
  black: "#262632",
  blackI: "#2a2a32",
  blackIII: "#353541",
  secondaryBlack: "#353540",
  ternaryBlack: "#1F1F28",
  offWhite: "#F3F3F7",
  secondaryBlackText: "#D8D8D8",
  red: "#F35C5C",
  grey500: "#919399",

  blue100: "#f4f4f7",
  blue200: "#e8e8ef",
  blue300: "#d2d2df",
  blue400: "#a5a5bf",
  blue500: "#7a789f",
  blue600: "#4c4b7f",
  blue700: "#201e5f",
  blueDark: "#1F1E5F",
  blueDarkSemiTransparent: "rgba(31, 30, 95, 0.1)",
  blueDarkII: "#1F1E5F",
  darkRoyalBlue: "#0359FF",
  lightRoyalBlue: "#00C2FF",
  semiRoyalBlue: "#0093FD",
  blueI: "#67C8FF",
  blueII: "#6BB8C8",
  blueIII: "#90C9FA",
  blueIV: "#0094FF",
  blueV: "#76CEFF",
  purple: "#AAADFF",
  purpleI: "#B399FF",
  purpleII: "#4C466E",
  lightPurple: "#1F1E5F99",
  semiPurple: "rgba(31, 30, 95, 0.8)",

  orange100: "#fff7f7",
  orange200: "#feefef",
  orange300: "#fddede",
  orange400: "#fabebf",
  orange500: "#f89d9d",
  orange600: "#f57d7d",
  orange700: "#f35d5c",
  red700: "#F35C5C40",
  red100: "#FC7070",
  red200: "#CF485E",
  red300: "#F18A9B",
  red400: "#FF7B90",
  red500: "#FF6C83",
  red600: "#FF8080",

  grey200: "#E5E7EB",

  teal100: "#f8fffe",
  teal200: "#f1fefc",
  teal300: "#e3fcf9",
  teal400: "#c5faf4",
  teal500: "#a9f7ee",
  teal600: "#8df5e9",
  teal700: "#71f2e3",
  teal800: "#69DDD6",
  green: "#52CC3E",
  greenI: "#3BB627",
  greenII: "#91DE76",
  greenIII: "#5AD675",
  greenIV: "#75A783",
  greenV: "#B3D687",
  greenVI: "#5EB67C",
  greenVII: "#CCE6AC",
  greenVIII: "#A3DDB3",
  greenIX: "#C2E793",
  greenX: "#7DD285",
  greenXI: "#B4E88C",
  yellow: "#FFC56F",
  yellowI: "#FFD79B",
  yellowII: "#E67D5F",
  yellowIII: "#FFBF5F",
  yellowIV: "#EF8A6C",
  yellowV: "#EFAF9C",
  yellowVI: "#FFC063",
  yellowVII: "#FF9A3D",
  yellowVIII: "#FFD363",
  yellowIX: "#FFCA63",
  yellowX: "#FFAB8F",
  yellowXI: "#F5E077",
  yellowXII: "#FFEEA8",
  crayolaYellow: "#FFEC8A",
  reddish: "#E06377",
  star: "#FF9F0A",
  lightGreen: "#C9D7B1",
  lightYellow: "#FFEBCC",
  lightPink: "#F4D5D5",
  darkPink: "#F1C2FA",
  lightBlue: "#D1EEFE",
  lightBlueI: "#82DEF1",
  lightBlueII: "#D6D6FF",
  skyblue: "#7BD0FF",
  lightPurpleI: "#DFE0F9",
  transparent: "transparent",
  background: "#F3F3F7",
  backgroundII: "rgba(3,89,255,0.1)",
  backgroundIII: "rgba(0, 147, 253, 0.1)",
  backgroundIV: "rgba(0, 194, 255, 0.1)",
  backgroundV: "rgba(113, 242, 227, 0.1)",
  bottomTabBackground: "#F3F3F7",
  secondaryBackground: palette.white,
  mainForeground: palette.black,
  primaryCardBackground: palette.white,
  tabsActive: palette.orange700,
  tabsInActive: palette.blue500,
  radioBackground: palette.blueDark,
  subTitle: palette.lightPurple,
  primary: palette.orange700,
  secondary: palette.blue700,
  accent: palette.teal700,
  blueBlack: palette.blueDark,
  blueWhite: palette.blueDark,
  whiteBlue: palette.white,
  greyBlack: palette.grey200,
  text: palette.blue700,
  icons: "#1F1E5F",
  orange: palette.orange700,
  blue: palette.blue700,
  teal: palette.teal700,
  blackWhite: palette.white,
  blackWhiteI: palette.white,
  blackWhiteII: "rgba(31, 30, 95, 0.1)",
  whiteBlack: palette.black,
  blueWhite500: palette.blue500,
  blueRed: palette.blueDark,
  border: "#E4E6F0",
  borderI: "#E3E3E3",
  blackII: "#242424",
  grey50: "#F9FAFB",
  grey100: "#F3F4F6",
  grey300: "#D1D5DB",
  grey350: "#BCBCCF",
  grey400: "#9CA3AF",
  grey600: "#CBCBDB",
  grey700: "#E8E8EF",
  grey800: "#353332FF",
  silver: "#F1F2F9",
  semiBlack: "rgba(31, 30, 95, 0.8)",
  drawerLight: "rgba(31, 30, 95, 0.9)",
  switchText: "rgba(31, 30, 95, 0.9)",
  semiGray: "rgba(172, 172, 172, 0.6)",
  blueDarkI: "#62628FFF",
  greyish: "#D8D8D8",
  greyishI: "#5D5D67",
  hawaiianBreeze: "#77CEDF",
  dot: "#DFDFE3",
  darkSlateGray: "#262632",
  brownBlue: "#854D00",
  goldWhite: "#FFCA63",
  darkYellow: "#FFB13D",
  periwinkle: "#BDABF0",
  salmonPink: "#FFAC90",
  paleBlue: "#CBEEF5",
  apricot: "#fbdaa3",
  greenishBlue: "#6FDFC5",
  pastelPurple: "#CC99FF",
  rosePink: "#FF80BF",
  goldenYellow: "#FFDD4F",
  lightBluishGray: "#D6D8FF",
  paleCyan: "#C5F1E7",
  blueCyan: "#272733",
  buttonBlueText: "#007AFF",
  blueVII: "#0094FF",
  blueVII40: "#0094FF40",
  blueVI: "#BDE7FF",
};
