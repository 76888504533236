import { firebaseAuth } from "contexts/FirebaseContext";
import firebase from "firebase/compat/app";
import { UserId } from "../@types/user";
import { db } from "contexts/FirebaseContext";
import { uniq } from "lodash";
import { InvitationCode, RegistrationCode } from "../@types/invitation/code";
import * as InvitationCodeService from "services/InvitationCodeService";

type SignUpValues = {
  email: string;
  password: string;
  name: string;
  invitationCode: string;
  terms: boolean;
};

export const attemptSignUp = async ({
  email,
  password,
  name,
  invitationCode,
  terms,
}: SignUpValues) => {
  const isInivitationCodeValid =
    await InvitationCodeService.isInivitationCodeValid(invitationCode, email);

  if (!isInivitationCodeValid) {
    throw new Error("Invitation code invalid");
  }

  const invitationCodeObject = await InvitationCodeService.findInvitationCode(
    invitationCode
  );
  await InvitationCodeService.incrementInvitationCode(invitationCode);

  try {
    await createUserOnFirebaseAuthentication(email, password);

    const userProfile = {
      locale: "en",
      invitationCode: invitationCodeObject,
      fullname: name,
    };

    const uid = firebaseAuth().currentUser?.uid;

    return await db
      .collection("users")
      .doc(uid)
      .set(userProfile, { merge: true });
  } catch (error) {
    throw error;
  }
};

/**
 * Sign Up user to firebase authentication.
 */
export const createUserOnFirebaseAuthentication = (
  email: string,
  password: string
) => {
  return new Promise<UserId>((resolve, reject) => {
    firebaseAuth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(async () => {
        // We deliberately don't set the displayName property for the
        // authentication because other parts of our infrastructure will use
        // that to make assumptions of the existence of the user. i.e. a non
        // empty displayName might show an onboarding in the app.
        const firebaseUser =
          await firebaseAuth().createUserWithEmailAndPassword(email, password);

        if (firebaseUser && firebaseUser.user) {
          resolve(firebaseUser.user.uid);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Sign Up user to firebase authentication.
 */
export const setDisplayName = (name: string) => {
  const user = firebaseAuth().currentUser;
  if (!user) throw new Error("No authenticated user");
  return user.updateProfile({
    displayName: name,
  });
};

/**
 * Retrieves the timezones of users based on invitation codes.
 *
 * @param {Array<string>} invitationCodes - An array of invitation codes.
 * @returns {Promise<Array<string>>} An array of unique timezones associated with the provided invitation codes.
 */
export async function getUsersTimezoneByInvitationCodes(
  invitationCodes: Array<string>
): Promise<Array<string>> {
  const usersSnapshot = await db
    .collection("users")
    .where("invitationCode.code", "in", invitationCodes)
    .get();

  const userTimezones: Array<string> = usersSnapshot.docs
    .map((doc) => doc?.data()?.timezone)
    .filter(Boolean);

  return uniq(userTimezones);
}

/**
 * Create the Firestore document for the user.
 */
export async function createUserProfile(
  uid: UserId,
  userProfile: {
    locale: string;
    saveBackup?: boolean;
    savedOlderActivitiesToHistory?: boolean;
    invitationCode?: InvitationCode;
    registrationCode?: RegistrationCode;
  }
) {
  return db.collection("users").doc(uid).set(userProfile, { merge: true });
}
