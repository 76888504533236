import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import * as CloudFunctions from "utils/firebase/cloudFunctions";
import { MfaType } from "../utils/constants";
import { LoadingButton } from "@mui/lab";
import { MIconButton } from "./@material-extend";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import { useSnackbar } from "notistack";
import useAuth from "../hooks/useAuth";
import { UnknownError } from "../@types/settings";
import { useCustomTheme } from "../theme/CustomThemeProvider";

type SelectMFATypeProps = {
  open: boolean;
  onClose: () => void;
  hideMfaModel: () => void;
  mfaType: MfaType;
};

export const UnEnrollMfaAuthenticationDialog = ({
  open,
  onClose,
  hideMfaModel,
  mfaType,
}: SelectMFATypeProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useCustomTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { logout } = useAuth();

  const onUnEnroll = async () => {
    try {
      setLoading(true);
      await CloudFunctions.unenrollMultiFactorAuthentication({
        mfaType,
      });

      await logout();
      setLoading(false);
      hideMfaModel();
      enqueueSnackbar("Successfully un-enroll mfa", {
        variant: "success",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } catch (error: UnknownError) {
      enqueueSnackbar("Failed to unEnroll mfa", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
      hideMfaModel();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "4px !important",
          backgroundColor: theme.styles.background["bg-primary"],
        },
      }}
      fullWidth
      maxWidth={"sm"}
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        style={{
          fontWeight: 700,
          fontSize: 24,
          color: theme.styles.text["text-primary"],
        }}
      >
        UnEnroll mfa
      </DialogTitle>
      <DialogContent>
        <Box alignItems={"flex-end"} justifyContent={"flex-end"}>
          <Typography>
            Are you sure you want to turn off MFA authentication? You'll need to
            log in again.
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          size="small"
          type="submit"
          loading={loading}
          variant="contained"
          color={"primary"}
          onClick={onUnEnroll}
        >
          Ok
        </LoadingButton>

        <Button color={"inherit"} variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
