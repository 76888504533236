import * as Yup from "yup";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  checkboxClasses,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
import { PATHS } from "../../../routes/paths";
// hooks
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
//
import { MIconButton } from "../../@material-extend";
import firebase from "firebase/compat/app";
import { UnknownError } from "../../../@types/settings";
import { useCustomTheme, Text } from "../../../theme/CustomThemeProvider";

// ----------------------------------------------------------------------
type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm({
  onMfa,
}: {
  onMfa: (resolver: firebase.auth.MultiFactorResolver) => void;
}) {
  const { login, setAttemptingLogin } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const { attemptingLogin } = useAuth();
  const theme = useCustomTheme();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("onSubmit", values);

      setAttemptingLogin(true);

      enqueueSnackbar("Logging in", {
        variant: "success",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });

      try {
        await login(values.email, values.password);

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error: UnknownError) {
        console.log("Error", error);
        setAttemptingLogin(false);

        const isMFARequired = error.code === "auth/multi-factor-auth-required";
        if (isMFARequired) {
          onMfa(error.resolver);
          return;
        }

        console.error(error);
        if (isMountedRef.current) {
          setSubmitting(false);

          if (error instanceof Error) {
            enqueueSnackbar("Login failed.", {
              variant: "error",
              autoHideDuration: 5000,
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              ),
            });
          }
        }
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={1.2}>
          <Text
            variant={"button2 - medium  (0%)"}
            color={theme.styles.text["text-black"]}
          >
            Email address
          </Text>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={null}
            disabled={attemptingLogin}
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <Text
            variant={"button2 - medium  (0%)"}
            color={theme.styles.text["text-black"]}
            pt={1}
          >
            Password
          </Text>

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label={null}
            disabled={attemptingLogin}
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: theme.styles.colors["action blue"]["blue-chat"],
                  },
                }}
              />
            }
            label={
              <Text
                variant={"button2 - medium  (0%)"}
                color={theme.styles.text["text-secondary"]}
              >
                Remember me
              </Text>
            }
          />

          <Link
            component={RouterLink}
            variant="subtitle2"
            color={"initial"}
            to={PATHS.resetPassword}
          >
            <Text
              variant={"button2 - medium  (0%)"}
              color={theme.styles.colors["action blue"]["blue-chat"]}
            >
              Forgot password?
            </Text>
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={attemptingLogin}
          sx={{
            backgroundColor: theme.styles.buttons.primary["button-primary"],
            "&:hover": {
              backgroundColor: theme.styles.buttons.primary["button-primary"],
            },
          }}
        >
          <Text
            variant={"button2 - medium  (0%)"}
            color={theme.styles.text["text-white"]}
          >
            Login
          </Text>
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
