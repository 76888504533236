import { CustomTheme } from "../types";

// ----------------------------------------------------------------------

export default function Divider(theme: CustomTheme) {
  return {
    MuiDivider: {
      defaultProps: {
        orientation: "horizontal", // Default orientation
        flexItem: false, // By default, Divider will not stretch flex items
      },
      styleOverrides: {
        root: {
          borderColor: theme.styles.stroke["stroke-primary"],
        },
      },
    },
  };
}
