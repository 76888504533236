import { LoadingButton } from "@mui/lab";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import { Form, FormikHelpers, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { QRCodeSVG } from "qrcode.react";

export type QrCodeWithInputFieldValues = {
  verifyCode: string;
};

const QrCodeWithInputFieldSchema = Yup.object().shape({
  verifyCode: Yup.string().required("required"),
});

type Props = {
  onSubmit: (
    values: QrCodeWithInputFieldValues,
    helpers: FormikHelpers<QrCodeWithInputFieldValues>
  ) => Promise<void>;
  qrCodeUrl: string;
};

export function ValidateTotpCode({ onSubmit, qrCodeUrl }: Props) {
  const qrCodeForm = useFormik<QrCodeWithInputFieldValues>({
    initialValues: {
      verifyCode: "",
    },
    validationSchema: QrCodeWithInputFieldSchema,
    onSubmit,
  });

  const { isSubmitting, handleSubmit, getFieldProps, errors, values } =
    qrCodeForm;

  return (
    <FormikProvider value={qrCodeForm}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item lg={12}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <QRCodeSVG height={220} width={220} value={qrCodeUrl} />
            </Box>
            <Stack paddingTop="32px" spacing={3}>
              <Typography variant="body1" align="center">
                Scan the QR code using your TOTP authentication app, then enter
                the generated verification code below. If you do not already
                have one, you can download an authentication app from the App
                Store or Play Store on your mobile device. Popular choices
                include Authy, Microsoft Authenticator, and Google
                Authenticator.
              </Typography>

              <TextField
                fullWidth
                {...getFieldProps("verifyCode")}
                placeholder="Enter code"
                type="text"
                label="Verification code"
                error={Boolean(errors.verifyCode)}
                helperText={
                  errors.verifyCode && "The code you entered doesn't match"
                }
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!values?.verifyCode}
              >
                Verify
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
