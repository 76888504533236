import { ReactNode, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import { useTranslation } from "react-i18next";

// material
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

// components
import Page from "components/Page";
import LoadingScreen from "components/LoadingScreen";
import { MIconButton } from "components/@material-extend";
import { ThemeProps, useCustomTheme } from "../theme/CustomThemeProvider";
import useAuth from "hooks/useAuth";
import AuthenticationHeader from "../pages/authentication/AuthenticationHeader";
import TermsFooter from "../components/TermsFooter";

// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  flexDirection: "column",
  height: "100vh",
  display: "flex",
  justifyContent: "space-between",
}));

const ContentStyle = styled(Box)(({ theme }) => ({
  maxWidth: 670,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  position: "relative",
}));

const LoadingIconContainer = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  background: "rgba(255,255,255,0.4)",
  zIndex: 2,
}));

const ContentContainer = styled(Box)<ThemeProps>(() => ({
  position: "relative",
}));

export default function AuthLayout({
  children,
  headTitle,
}: {
  children: ReactNode;
  headTitle: string;
}) {
  const { attemptingLogin, showLoginSuccessMessage } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useCustomTheme();

  useEffect(() => {
    if (showLoginSuccessMessage) {
      enqueueSnackbar("Login successful!", {
        variant: "success",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    }
  }, [showLoginSuccessMessage, enqueueSnackbar, closeSnackbar]);

  return (
    <RootStyle
      headTitle={headTitle}
      sx={{
        backgroundColor: theme.styles.background["bg-blue-100"],
        backgroundImage: "url(/static/bg_img.png)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
    >
      {attemptingLogin && (
        <LoadingIconContainer>
          <LoadingScreen sx={{ backgroundColor: "transparent" }} />
        </LoadingIconContainer>
      )}

      <AuthenticationHeader />

      <ContentContainer>
        <ContentStyle>{children}</ContentStyle>
      </ContentContainer>
      <TermsFooter />
    </RootStyle>
  );
}
