import { useState } from "react";
import firebase from "firebase/compat/app";
import { useSnackbar } from "notistack";
import { UnknownError } from "../@types/settings";

/**
 * Hook to use Firebase' multi-factor authentication. Before you can use
 * the exposed functions, make sure to initialize a reCAPTCHA verifier:
 * `new firebase.auth.RecaptchaVerifier("id attribute")`.
 */
export function useMultiFactorLogin(
  resolver: Readonly<firebase.auth.MultiFactorResolver>
) {
  const [verificationId, setVerificationId] = useState<string>("");
  const [requested, setRequested] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  async function requestAuthenticationCode(
    verifier: Readonly<firebase.auth.ApplicationVerifier>,
    hint: Readonly<firebase.auth.MultiFactorInfo>
  ) {
    setRequested(true);

    const phoneInfoOptions = {
      multiFactorHint: hint,
      session: resolver.session,
    };
    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

    // This promise only resolves once the captcha is solved
    try {
      const id = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        verifier
      );
      setVerificationId(id);
      enqueueSnackbar("Verification Code Sent Successfully!", {
        variant: "success",
      });
    } catch (error: UnknownError) {
      console.error(error);
      enqueueSnackbar("Failed to Send Verification Code", { variant: "error" });
    } finally {
      setRequested(false);
    }
  }

  async function resolveSignIn(verificationCode: Readonly<string>) {
    const cred = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    const multiFactorAssertion =
      firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

    // Complete sign-in. This will also trigger the Auth state listeners.
    await resolver.resolveSignIn(multiFactorAssertion);
  }

  return {
    requesting: requested,
    requestAuthenticationCode,
    resolveSignIn,
  };
}
