import * as CloudFunctions from "utils/firebase/cloudFunctions";
import {
  InvitationCodeCode,
  RegistrationCode,
  SignupCodeReturnType,
} from "../@types/invitation/code";
import { collections } from "utils/constants";
import { db } from "contexts/FirebaseContext";
import { UserId } from "../@types/user";
import firebase from "firebase/compat/app";
import { DepartmentQuestionParam } from "../components/DropDownList";
import { extractValuesFromCommaSeparatedString } from "../utils/extractValuesFromCommaSeparatedString";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import { UnknownError } from "../@types/settings";

/**
 * Check invitiation code is valid
 *
 */
export const isInivitationCodeValid = async (
  invitationCode: InvitationCodeCode,
  email?: string
) => {
  let checkedInvitationCode = await findInvitationCode(invitationCode, email);

  const codeIsValid = checkedInvitationCode?.isValid;

  return codeIsValid;
};

/**
 * Increment invitation code usage
 */
export const incrementInvitationCode = async (
  invitationCode: InvitationCodeCode
) => {
  const trimmedInvitationCode = invitationCode.trim();
  try {
    const response = await CloudFunctions.incrementUsage({
      code: trimmedInvitationCode,
    });
    return response.data;
  } catch {
    return false;
  }
};

/**
 * Get the signed in user's invitation code
 */
export async function getMyInvitationCode(userId: UserId) {
  const ref = db.collection(collections.USERS).doc(userId);

  const document = await ref.get();
  const data = document.get("invitationCode");

  if (data) {
    const code = data.invitationCode;
    return findInvitationCode(code);
  }

  // Instead of returning null we just pretend the user has
  // an "invalid" invitation code
  return { chat: { chatEnabled: false }, suspended: true };
}

/**
 * Check if user has chat enabled on invitation code
 */
export async function hasChatEnabledOnInvitationCode(userId: UserId) {
  const invitationCode = await getMyInvitationCode(userId);

  if (invitationCode?.chat?.enableChat) {
    return true;
  }

  return false;
}

/**
 * Check if user has video call enabled on invitation code
 */
export async function hasVideoCallEnabledOnInvitationCode(userId: UserId) {
  const invitationCode = await getMyInvitationCode(userId);

  if (invitationCode?.videoCall?.enableVideoCall) {
    return true;
  }

  return false;
}

export const getPermissionBasedInvitationCode = async ({
  uid,
}: {
  uid?: string;
}): Promise<{
  invitationCodes: Array<string>;
  isEnabledDemographicQuestionFilters: boolean;
}> => {
  if (!uid) {
    return { invitationCodes: [], isEnabledDemographicQuestionFilters: false };
  }

  const permissionGroupRef = db
    .collection(collections.CORPORATE_DASHBOARD_PERMISSION_GROUP)
    .doc(uid);

  const permissionGroupSnapshot = await permissionGroupRef.get();
  const permissionGroupData = permissionGroupSnapshot?.data();

  const isEnabledDemographicQuestionFilters =
    permissionGroupData?.filterByDemographicQuestions;

  if (!permissionGroupSnapshot?.exists) {
    return { invitationCodes: [], isEnabledDemographicQuestionFilters };
  }

  const organisationId = permissionGroupData?.organisationId;

  if (organisationId?.length > 0) {
    const invitationCodes = await getInvitationCodeForGroupIds(
      [organisationId],
      collections.ORGANISATION
    );

    return { invitationCodes, isEnabledDemographicQuestionFilters };
  } else {
    const invitationCodes = await getInvitationCodeForGroupIds(
      permissionGroupData?.invitationCodeGroups,
      collections.INVITATION_CODE_GROUPS
    );
    return { invitationCodes, isEnabledDemographicQuestionFilters };
  }
};

/**
 * Returns a comma-separated string of unique invitation codes for the given group IDs,
 * filtered by the provided collection name.
 * @param {Array<string>} groupIds - An array of group IDs to filter invitation codes by.
 * @param {string} collectionName - The name of the collection to query for invitation codes.
 * @returns {Promise<string>} - A comma-separated string of unique invitation codes.
 */
export const getInvitationCodeForGroupIds = async (
  groupIds: Array<string>,
  collectionName: string
): Promise<Array<string>> => {
  let invitationCodes: Array<string> = [];

  const invitationCodesQuery = db
    .collection(collectionName)
    .where(firebase.firestore.FieldPath.documentId(), "in", groupIds);

  const invitationCodesSnapshot = await invitationCodesQuery.get();

  invitationCodesSnapshot.forEach((invitationCodeDoc) => {
    const invitationCodeData = invitationCodeDoc.data();
    invitationCodes = invitationCodes.concat(
      ...invitationCodeData?.invitationCodes
    );
  });

  return invitationCodes;
};

export async function getFirstFiveEnglishQuestionsFromInvitationCodes({
  invitationCode,
}: {
  invitationCode: string;
}) {
  const invitationCodeList =
    extractValuesFromCommaSeparatedString(invitationCode);
  const collectionRef = db.collection("invitation_codes");
  const englishQuestions: DepartmentQuestionParam[] = [];

  const queryPromises = invitationCodeList.map(async (code) => {
    const docSnapshot = await collectionRef.doc(code).get();
    const data = docSnapshot?.data();

    if (data?.demographicQuestions) {
      const demographicEnglishQuestions = data?.demographicQuestions?.en;
      for (const [index, question] of demographicEnglishQuestions?.entries()) {
        const bigQueryQuestionIndex =
          question?.mixpanelQuestionIndex ?? Number(index ?? 0) + 1;
        englishQuestions.push({
          bigQueryColumnName: bigQueryQuestionIndex,
          question: question.question,
          invitationCode: data?.invitationCode,
        });
      }
    }
  });

  await Promise.all(queryPromises);
  return englishQuestions;
}

/**
 * find invitation code
 *
 */
export const findInvitationCode = async (
  invitationCode: InvitationCodeCode,
  email?: string
) => {
  const trimmedInvitationCode = invitationCode.trim();
  try {
    const response = await CloudFunctions.checkCode({
      code: trimmedInvitationCode,
      emailToCheck: email,
    });
    return response.data;
  } catch {
    return false;
  }
};

/**
 * find signup code
 * this method first check if the entered code is registration code then return that
 * otherwise it returns invitation code
 * in response it will return complete code, so that we can
 * store tha
 *
 */

export async function findSignupCode(
  code: string,
  email?: string
): Promise<SignupCodeReturnType> {
  if (!code.trim()) {
    return Promise.reject();
  }

  try {
    const registrationCodeResponse = await findRegistrationCode(code, email);
    if (registrationCodeResponse && !isEmpty(registrationCodeResponse)) {
      return {
        response: registrationCodeResponse,
        signupCodeType: "registrationCode",
      };
    } else {
      const invitationCodeResponse = await findInvitationCode(code, email);
      return {
        response: invitationCodeResponse,
        signupCodeType: "invitationCode",
      };
    }
  } catch (error: UnknownError) {
    Sentry.captureException(error);
    return Promise.reject();
  }
}

/**
 * Increment registration code usage
 */
export async function incrementRegistrationCode(code: string) {
  if (!code.trim()) {
    return false;
  }

  try {
    const response = await CloudFunctions.incrementRegistrationCodeUsage({
      code,
    });
    return response.data;
  } catch (error: UnknownError) {
    Sentry.captureException(error);
    return false;
  }
}

async function findRegistrationCode(code: string, email?: string) {
  if (!code.trim()) {
    return false;
  }

  try {
    const response = await CloudFunctions.findRegistrationCode({
      code,
      emailToCheck: email,
    });
    return response.data as RegistrationCode;
  } catch (error: UnknownError) {
    Sentry.captureException(error);
    return false;
  }
}
