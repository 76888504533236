import { useState } from "react";

import { Box, Typography } from "@mui/material";

import { ButtonLink } from "components/ButtonLink";
import { EnrollMFADialog } from "components/EnrollMFADialog";
import { PATHS } from "routes/paths";
import { MFAStep } from "../../@types/authentication";
import { styled } from "@mui/material/styles";
import { ThemeProps } from "../../theme/CustomThemeProvider";
import AuthLayout from "../../layouts/AuthLayout";

const Container = styled(Box)<ThemeProps>(({ theme }) => ({
  backgroundColor: theme.styles.colors.white.white,
  padding: theme.spacingVal.xxxl,
  overflow: "hidden",
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
}));

const Title = styled(Typography)<ThemeProps>(({ theme }) => ({
  color: theme.styles.text["text-black"],
  textAlign: "center",
  marginBottom: 40,
}));

export function EnrollMFAPage() {
  const [step, setStep] = useState<MFAStep>(MFAStep.ENROLL);

  if (step !== MFAStep.ENROLL) {
    let title = "Successfully enrolled";
    let message = "The factor was added to your account";
    if (step === MFAStep.ERROR) {
      title = "An error occurred";
      message =
        "An error occurred. Try again or contact support if the issue persists";
    }

    return (
      <AuthLayout headTitle={"Enroll in two-factor authentication"}>
        <Container>
          <Title variant="h4 - medium (-1%)">{title}</Title>

          <Typography textAlign="center">{message}</Typography>
          {/* <a> tag forces the browser to navigate which causes a page reload and reloads
            the authentication handler.

            onAuthStateChanged handler loads therapist profile and other metadata
            but fires to early in this registration flow(when we call signInWithEmailLink).

            Page reload fixes that problem.
          */}
          <ButtonLink to={PATHS.root} title={"Back"} aTag />
        </Container>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout headTitle={"Enroll in two-factor authentication"}>
      <Container>
        <Title variant="h4 - medium (-1%)">
          Enroll in two-factor authentication
        </Title>

        <EnrollMFADialog
          onSuccess={() => {
            setStep(MFAStep.SUCCESS);
          }}
          onError={() => {
            setStep(MFAStep.ERROR);
          }}
        />
      </Container>
    </AuthLayout>
  );
}
