type TextStyle = {
  fontSize: number;
  fontFamily: string;
  fontWeight: number;
  fontStyle?: string;
  letterSpacing?: number;
  lineHeight?: number;
};

const typography: Record<string, TextStyle> = {
  "h1 - medium (-2%)": {
    fontSize: 24,
    fontFamily: "Good Sans-Medium",
    fontWeight: 500,
    fontStyle: "normal",
    letterSpacing: -0.48,
  },
  "h2 - bold  (-1%)": {
    fontSize: 20,
    fontFamily: "Good Sans-Bold",
    fontWeight: 700,
    fontStyle: "normal",
    letterSpacing: -0.2,
  },
  "h3-bold(-1%)": {
    fontSize: 16,
    fontFamily: "Good Sans-Bold",
    fontWeight: 700,
    fontStyle: "normal",
    letterSpacing: -0.16,
  },
  "h4 - medium (-1%)": {
    fontSize: 20,
    fontFamily: "Good Sans-Medium",
    fontWeight: 500,
    fontStyle: "normal",
    letterSpacing: -0.2,
  },
  "h5 - medium (-1%)": {
    fontFamily: "Good Sans-Medium",
    fontSize: 30,
    fontStyle: "normal",
    fontWeight: 500,
  },
  "h0 - bold (-3%)": {
    fontSize: 60,
    fontFamily: "Good Sans-Bold",
    fontWeight: 700,
    fontStyle: "normal",
    letterSpacing: -1.8,
  },
  "title1 - medium (-1%)": {
    fontSize: 18,
    fontFamily: "Good Sans-Medium",
    fontWeight: 500,
    fontStyle: "normal",
    letterSpacing: -0.18,
  },
  "title1 - medium (-2%)": {
    fontSize: 18,
    fontFamily: "Good Sans",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: -0.18,
  },
  "title2  - medium  (-1%)": {
    fontSize: 16,
    fontFamily: "Good Sans-Medium",
    fontWeight: 500,
    fontStyle: "normal",
    letterSpacing: -0.16,
  },
  "title3 - bold  (-1%)": {
    fontSize: 16,
    fontFamily: "Good Sans-Bold",
    fontWeight: 700,
    letterSpacing: -0.16,
  },
  "body1 - regular  (0%)": {
    fontSize: 14,
    fontFamily: "Good Sans",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: 0,
  },
  "body2 - bold  (0%)": {
    fontSize: 14,
    fontFamily: "Good Sans-Bold",
    fontWeight: 700,
    fontStyle: "normal",
    letterSpacing: 0,
  },
  "body3 - medium  (0%)": {
    fontSize: 12,
    fontFamily: "Good Sans-Medium",
    fontWeight: 500,
    fontStyle: "normal",
    letterSpacing: 0,
  },
  "body4 - regular  (0%)": {
    fontSize: 12,
    fontFamily: "Good Sans",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: 0,
  },
  "body5 - regular (0%)": {
    fontSize: 16,
    fontFamily: "Good Sans",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: 0,
  },
  "body6-regular(0%)": {
    fontSize: 14,
    fontFamily: "Good Sans",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: 0,
  },
  "body6-medium(0%)": {
    fontFamily: "Good Sans-Medium",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 500,
  },
  "caption - regular  (0%)": {
    fontSize: 10,
    fontFamily: "Good Sans",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: 0,
  },
  "label1 - medium  (2%)": {
    fontSize: 12,
    fontFamily: "Good Sans-Medium",
    fontWeight: 500,
    fontStyle: "normal",
    letterSpacing: 0.24,
  },
  "label2 - medium  (2%)": {
    fontSize: 10,
    fontFamily: "Good Sans-Medium",
    fontWeight: 500,
    fontStyle: "normal",
    letterSpacing: 0.2,
  },
  "label3 - regular (2%)": {
    fontSize: 8,
    fontFamily: "Good Sans",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: 0.16,
  },
  "button1 - medium  (-1%)": {
    fontSize: 16,
    fontFamily: "Good Sans-Medium",
    fontWeight: 500,
    fontStyle: "normal",
    letterSpacing: -0.16,
  },
  "button2 - medium  (0%)": {
    fontSize: 14,
    fontFamily: "Good Sans-Medium",
    fontWeight: 500,
    fontStyle: "normal",
    letterSpacing: 0,
  },
  "link 1 - regular": {
    fontSize: 14,
    fontFamily: "Good Sans",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: 0,
  },
};
export default typography;
