import { CCRoles } from "../@types/role/CCRoles";
import {
  tip1,
  tip10,
  tip11,
  tip12,
  tip13,
  tip14,
  tip15,
  tip16,
  tip17,
  tip18,
  tip19,
  tip2,
  tip20,
  tip21,
  tip22,
  tip3,
  tip4,
  tip5,
  tip6,
  tip7,
  tip8,
  tip9,
  mentalWellbeing,
  seasonalDepression,
  leadshipRoundtableWebinar,
  leadershipAndMentalHealth,
  anxietyAndMeditation,
  streeInTheWorkplace,
} from "../assets";

export const ROLES: CCRoles = {
  CORPORATE_DASH_READ: "corp_dsh_r",
  CORPORATE_DASH_SUPER_ADMIN: "corp_dsh_supr_admn",
  SUPER_ADMIN: "supr_admn",
};

export const INDUSTRY_AVERAGE = "Industry average";

// arr containing roles required to access chat side menu & related path
export const CORPORATE_DASH_ADMINS = [
  ROLES.CORPORATE_DASH_SUPER_ADMIN,
  ROLES.CORPORATE_DASH_READ,
  ROLES.SUPER_ADMIN,
];

export const SUPER_ADMIN_ROLE = [ROLES.SUPER_ADMIN];

export const collections = {
  C_CLAIM_AUTOSYNC_UTIL: "c_claim_autosync_util",
  THERAPISTS: "therapists",
  CONFERENCE_CALLS: "conference_call_rooms",
  CHATS: "chats",
  USERS: "users",
  MESSAGES: "messages",
  CONVERSATION: "conversation",
  SESSIONS: "sessions",
  CORPORATE_DASHBOARD_PERMISSION_GROUP: "corporate_dashboard_permission_group",
  ORGANISATION: "organisations",
  INVITATION_CODE_GROUPS: "invitation_code_groups",
} as const;

export const KEY_WELLBEING_METRICES = [
  {
    title: "Social needs",
    icon: "cil:smile",
    progressTitle: "Belonging",
    category: "W1",
    rating: 60,
    description: `"I feel a sense of belonging in my company"`,
    averageBarAnnotations: {
      y1: 66,
      y2: 69,
    },
  },
  {
    title: "Foundational needs",
    icon: "akar-icons:shield",
    progressTitle: "Trust",
    category: "W2",
    rating: 70,
    description: `"I can trust people in my company."`,
    averageBarAnnotations: {
      y1: 66,
      y2: 69,
    },
  },
  {
    title: "Growth needs",
    icon: "octicon:zap-24",
    progressTitle: "Energy",
    category: "W3",
    rating: 80,
    description: `"In most of my work tasks, I feel energised."`,
    averageBarAnnotations: {
      y1: 66,
      y2: 69,
    },
  },
];

export const WORK_WELLBEING_OUTCOMES = [
  {
    title: "Happiness",
    category: "W4",
    rating: 60,
    description: `"I feel happy at work most of the time"`,
    averageBarAnnotations: {
      y1: 68,
      y2: 71,
    },
  },
  {
    title: "Purpose",
    category: "W5",
    rating: 70,
    description: `"My work has a clear sense of purpose"`,
    averageBarAnnotations: {
      y1: 73,
      y2: 76,
    },
  },
  {
    title: "Stress-free",
    category: "W6",
    rating: 75,
    description: `"I feel stressed at work most of the time"`,
    averageBarAnnotations: {
      y1: 66,
      y2: 69,
    },
  },
  {
    title: "Satisfaction",
    category: "W7",
    rating: 80,
    description: `"Overall, I am completely satisfied with my job"`,
    averageBarAnnotations: {
      y1: 67,
      y2: 69,
    },
  },
];

export const TIPS = [
  {
    image: tip1,
    description:
      "Ensure that the **goals of the company** and how the company contributes to society is visible to employees. Emphasise how the work that employees perform contributes to this greater goal.",
  },
  {
    image: tip2,
    description:
      "Give employees **some freedom** in deciding **what tasks/projects** they will work on.",
  },
  {
    image: tip3,
    description:
      "Offer employees a certain amount of time to work on **projects that they find meaningful.**",
  },
  {
    image: tip4,
    description:
      "Allow employees to have the freedom to work on tasks or projects that align with their skills or interests: Fostering **a sense of agency and autonomy** is highly important for employees as it helps them feel motivated and accountable for their tasks.",
  },
  {
    image: tip5,
    description:
      "**Offer training programs** to managers and leaders **to increase the personal resources** of your employees (e.g., self-efficacy, resilience and optimism). Research has shown that building these skills is important to increase employees' engagement.",
  },
  {
    image: tip6,
    description:
      "**Provide training programs** for leaders and managers **to develop inclusive leadership:** leaders' actions can have a significant impact on the sense of belonging of the team. When employees trust their leader and feel supported, they are more likely to feel **a sense of belonging at work.** On the opposite side, employees who feel neglected and unappreciated tend to be less engaged in their work. Therefore, building an inclusive work culture where every team member feels valued and empowered is key to foster a sense of belonging in the workplace.",
  },
  {
    image: tip7,
    description:
      "Integrate **health-promoting programs** at work that help employees release stress, maintain a healthy lifestyle and be more energetic. This can include practising mindfulness or relaxation, providing healthy food options or taking short breaks for physical activity.",
  },
  {
    image: tip8,
    description:
      "Create opportunities for employees to build networks in the workplace: **Fostering social interaction** through the creation of places and events where people can meet and interact in a more informal setting usually results in employees finding their own social support within the organisation.",
  },
  {
    image: tip9,
    description:
      "Implement a sensitive and up-to-date harassment policy, and **act swiftly in case there are signs of bullying or harassment in the workplace.** Negative interactions at work have a far greater impact on employee well-being than positive interactions. In some cases, transferring a problematic employee, or finding ways to keep two warring workers apart by separating work tasks or physical locations, may be needed.",
  },
  {
    image: tip10,
    description:
      "Empower your employees by giving them responsibility for their work. **Avoid micromanaging and instead demonstrate trust** by allowing them to make decisions and be accountable for the results of those decisions. By doing so, you show that you value their expertise and trust them to do their job effectively.",
  },
  {
    image: tip11,
    description:
      "**Act on feedback:** encourage open communication and feedback from employees, and show them that their opinions are valued by taking action on their feedback. When employees see the tangible effects of their feedback, their trust in the organisation will grow.",
  },
  {
    image: tip12,
    description:
      "**Be honest and transparent** about company decisions, policies and changes. Communicating openly and honestly with your employees allows you to create an environment of trust where they feel valued and informed.",
  },
  {
    image: tip13,
    description:
      "Multiple factors can influence happiness at work. It is important to **keep track of the drivers of work wellbeing** to identify potential risks and take timely action to ensure a happy workforce in the future.",
  },
  {
    image: tip14,
    description:
      "Multiple factors can influence job satisfaction. **Check the drivers of work wellbeing** to pinpoint what can be done to increase employees' level of satisfaction.",
  },
  {
    image: tip15,
    description:
      "Implementing effective interventions to reduce employees' levels of stress require **finding out why employees feel stressed in the first place.** There can be multiple reasons why employees feel stressed at work. Usually, high levels of stress are caused by a **high workload** and difficulties meeting deadlines. Another important predictor of stress can be found in the social domain, e.g., **do employees feel supported and valued at work?** Check the drivers of work wellbeing to gain a better understanding of what could be causing employees to feel stressed.",
  },
  {
    image: tip16,
    description:
      "**Be mindful of employees' work-life boundaries.** Support does not necessarily mean that employees need to develop an extensive network of close friends at work to share intimacies with.",
  },
  {
    image: tip17,
    description:
      "**Prioritise inclusivity:** encourage open dialogue and transparency where employees feel safe to express their work-related concerns, including feelings of exclusion, discrimination or other issues that may impact their sense of belonging. Make sure that activities carried out within the organisation take into consideration the diverse needs of employees.",
  },
  {
    image: tip18,
    description:
      "**Practise active listening:** give your full attention to your employees when they speak, make a deliberate effort to ask them questions to clarify what they are saying so you can truly understand their perspective.",
  },
  {
    image: tip19,
    description:
      "**Show appreciation frequently:** acknowledge your employees' hard work and efforts by being fully outspoken about the positive things you genuinely appreciate about them.",
  },
  {
    image: tip20,
    description:
      "Plan work sustainably: **ensure that employees have a realistic workload** and that they don't feel overwhelmed or exhausted by their tasks. Implement tools to effectively manage and measure their workload (such as using a workload management platform) and make sure that their challenges motivate rather than stress them out.",
  },
  {
    image: tip21,
    description:
      "**Prioritise time off:** Reframe time off as a need that benefits the organisation's well-being, and make sure holiday policies are clear to all employees and that the process for booking time off is simple and accessible.",
  },
  {
    image: tip22,
    description:
      "**Recognise and reward employees** for their hard work and achievements: whether through incentives, promotions, or public recognition, recognition initiatives help employees feel valued by giving them a sense of accomplishment, which can lead to even higher levels of energy and motivation.",
  },
];

export const DEFAULT_MESSAGES_SENT_DATA = {
  totalMessages: 0,
  totalUsers: 0,
  messagesSentChartData: [],
  lastTwoMonthDifference: 0,
};

export const DEFAULT_LIVE_SESSIONS_DATA = {
  totalUsers: 0,
  totalSessions: 0,
  liveSessionChartData: [],
  lastTwoMonthDifference: 0,
};

export const DEFAULT_COUNSELLOR_STAR_RATING_DATA = {
  averageRating: 0,
  starRatingData: [],
};

export const ARTICLES = [
  {
    title: "The importance of mental well-being in the workplace",
    description:
      "It's a shared responsibility between employers and employees to create a culture that fosters mental well-being and supports those with mental health challenges. Kyan Health offers a comprehensive solution to assist employees in managing their mental well-being, ultimately leading to improved productivity, job satisfaction, and a more robust bottom line.",
    date: "OCT 10, 2023",
    image: mentalWellbeing,
    link: "https://www.kyanhealth.com/mental-well-being-in-the-workplace/",
  },
  {
    title: "Seasonal Affective Disorder (Seasonal Depression)",
    description:
      "Even though it’s normal to feel happier and more vibrant when the sun is shining, the change in seasons can have a greater effect on some employees, and may lead to symptoms of depression that threaten engagement and productivity.",
    date: "SEP 15, 2023",
    image: seasonalDepression,
    link: "https://www.kyanhealth.com/seasonal-affective-disorder/",
  },
  {
    title:
      "Preventing Anxiety in the Workplace | Leadership Roundtable Webinar",
    description:
      "What causes anxiety in the workplace? How can you proactively identify, address, and prevent anxiety-related challenges that impact wider teams and leaders? What steps can organisations take to reduce stigma and encourage open conversations about anxiety and other mental health issues? These were the key questions discussed in the Leadership Roundtable webinar hosted by Kyan Health in recognition of Mental Health Awareness Week. If you missed the webinar, we invite you to read our article or watch the recording of the session at the end of the article for valuable insights shared by our esteemed speakers from Credit Suisse and On Running on the topic of anxiety prevention.",
    date: "JUN 30, 2023",
    image: leadshipRoundtableWebinar,
    link: "https://www.kyanhealth.com/preventing-anxiety-in-the-workplace/",
  },
  {
    title:
      "Leadership and Mental Health: Why and How to Prioritise your Mental Well-Being as a Leader",
    description:
      "By making your mental health a priority, you do not only take care of yourself but also create an environment that supports the well-being of your team. This can lead to increased productivity, higher employee engagement, and overall organisational success.",
    date: "JUN 15, 2023",
    image: leadershipAndMentalHealth,
    link: "https://www.kyanhealth.com/leadership-and-mental-health-why-and-how-to-prioritise-your-mental-well-being-as-a-leader/",
  },
  {
    title:
      "Anxiety and Meditation: Navigating the Path to Inner Peace and Well-being",
    description:
      "Anxious thoughts can be overwhelming, they can hinder our decision making, and prevent us from taking action. They can even create a cycle of worry and overthinking that keeps us trapped in a never-ending loop of growing anxiety, where each thought brings on the next, intensifying our distress. So how can we break free from this exhausting cycle? Fortunately, research has shown that various techniques, including meditation, can effectively alleviate anxiety. In this article, we'll look at the symptoms of anxiety and explore the benefits of meditation for cultivating a healthy mind and reducing anxious thoughts. To help you practise controlling anxious thoughts through meditation, we have selected a Kyan meditation at the end of the article for you to try. So keep reading to learn more about anxiety, and discover the power of meditation to find inner peace and calm!",
    date: "MAY 31, 2023",
    image: anxietyAndMeditation,
    link: "https://www.kyanhealth.com/anxiety-and-meditation/",
  },
  {
    title:
      "Stress in the workplace: Identifying the Causes and Finding Solutions",
    description:
      "Employers and leaders can take proactive steps to reduce workplace stress and promote a healthy work culture. By acknowledging the sources of stress and working to address them, organisations can create a supportive environment that promotes employee well-being, engagement and productivity.",
    date: "APR 21, 2023",
    image: streeInTheWorkplace,
    link: "https://www.kyanhealth.com/stress-in-the-workplace/",
  },
];

export const OUTCOMES_OF_WELLBEING_AT_WORK_MODAL_DATA = {
  title: "Outcomes of Wellbeing at work and Work Wellbeing Score",
  description: `Outcomes are factors that indicate the level of wellbeing of employees. 

The Work Wellbeing Score is obtained by assigning points to each of the Work Wellbeing Outcomes on a scale between 40 and 100, and then estimating their average.

The Work Wellbeing Score has been developed by Indeed under the guidance of Dr. Sonja Lyubomirsky and Prof. Jan-Emmanuel de Neve, based on scientific standards for measuring wellbeing which are followed by the OECD and the Oxford Wellbeing Research Centre.`,
};

export const DRIVERS_OF_WELLBEING_AT_WORK_MODAL_DATA = {
  title: "Drivers of Wellbeing at work",
  description: `Drivers are the factors that promote employee wellbeing, allowing the conditions for employees to have their needs met, to flourish, and thrive. 

The Work Wellbeing Needs Fulfilment Score is obtained by assigning points to each of the Work Welllbeing Drivers on a scale between 40 and 100, and then estimating their average.

The Industry Average benchmark is based on research led by Dr. Sonja Lyubomirsky and Prof. Jan-Emmanuel de Neve at the Oxford Wellbeing Research Centre, in cooperation with Indeed.`,
};

export enum CountriesDropdownOptions {
  "ALL_COUNTRIES" = "All",
}

export enum MfaType {
  PHONE = "phone",
  TOTP = "totp",
}
