import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Divider,
  CircularProgress,
  Card,
  Alert,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useAuth from "../../hooks/useAuth";
import {
  sendEmailVerificationCode,
  verifyEmailOtp,
} from "../../utils/firebase/cloudFunctions";
import { UnknownError } from "../../@types/settings";

const StyledImage = styled("img")(() => ({
  width: "15%",
  height: "15%",
  objectFit: "cover",
}));

const VerifyEmailScreen = () => {
  const [inputValues, setInputValues] = useState(Array(4).fill(""));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [open, setOpen] = useState(false);

  const { user, setShouldVerifyEmail } = useAuth();
  const otpCode = user?.emailVerification?.otp?.code ?? "";

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setError("");
    const { value } = event.target;
    if (value.length > 1) return;

    const newValues = [...inputValues];
    newValues[index] = value;
    setInputValues(newValues);

    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1]?.focus();
    } else if (!value && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResendCode = async () => {
    try {
      setError("");
      setLoading(true);

      const emailVerificationViaOtpResponse = await sendEmailVerificationCode({
        emailVerification: {
          email: user?.email,
        },
      });

      if (emailVerificationViaOtpResponse?.data === "sent_otp_via_email") {
        setOpen(true);
        window.location.reload();
      }
    } catch (err: UnknownError) {
      setError(err?.message ?? "");
    } finally {
      setLoading(false);
    }
  };

  const handleNext = useCallback(async () => {
    try {
      const resultantInput = inputValues.join("")?.trim();

      if (resultantInput?.length < 4) {
        return;
      }

      if (String(otpCode) === String(resultantInput)) {
        setLoading(true);
        await verifyEmailOtp({
          code: resultantInput,
          uid: user?.uid,
        });
        setShouldVerifyEmail(false);
        setLoading(false);
      } else {
        setError(
          "The code you entered is either invalid or has expired. Please request a new one."
        );
      }
    } catch {
      setError(
        "The code you entered is invalid or has expired. Please request a new one"
      );
    }
  }, [inputValues, otpCode, user?.uid, setShouldVerifyEmail]);

  useEffect(() => {
    if (inputValues.every((val) => val !== "")) {
      handleNext();
    }
  }, [inputValues, handleNext]);

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert variant="filled" severity="success">
            An OTP has been sent to your email. Please check your inbox to
            continue
          </Alert>
        </Snackbar>
      </Box>
      <Card
        style={{
          padding: 4,
          borderRadius: 4,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Typography variant="h5" mt={2} mb={2}>
          Verify your email
        </Typography>
        <Divider />

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <StyledImage
            loading="lazy"
            src="/static/onboarding/kyan_icon_black.png"
            alt="Kyan Health"
          />
        </Box>

        <Box mt={10}>
          <Typography>
            We've sent a verification email to your address. Please check your
            inbox
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {user?.email}
          </Typography>
          <Typography mt={1}>
            Please complete your email verification by entering the code sent to
            your inbox
          </Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            gap: 4,
            justifyContent: "center",
            marginTop: 15,
            borderRadius: 6,
          }}
        >
          {inputValues.map((value, index) => (
            <TextField
              key={index}
              style={{
                width: 55,
                height: 55,
                textAlign: "center",
                borderRadius: 5,
                border: `1px solid`,
              }}
              value={value}
              onChange={(e) => handleInputChange(index, e)}
              inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
              inputRef={(el) => {
                inputRefs.current[index] = el;
              }}
              disabled={loading}
              variant="outlined"
            />
          ))}
        </Box>

        {error && (
          <Typography
            mt={3}
            style={{
              color: "red",
              fontSize: "0.9rem",
            }}
          >
            ⓘ {error}
          </Typography>
        )}

        <Box
          style={{
            marginTop: 20,
            marginBottom: 20,
            display: "flex",
            justifyContent: "center",
            gap: "98px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} color="inherit" /> : "Next"}
          </Button>
          <Button
            onClick={handleResendCode}
            disabled={loading}
            color="secondary"
          >
            ↻ Resend code
          </Button>
        </Box>
      </Card>
    </Container>
  );
};

export default VerifyEmailScreen;
