import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import closeFill from "@iconify/icons-eva/close-fill";
import GoogleLogin from "react-google-login";
import { MIconButton } from "./@material-extend";
import useAuth from "../hooks/useAuth";
import { webClientId } from "../config";
import firebase from "firebase/compat/app";
import { UnknownError } from "../@types/settings";
import { Text, useCustomTheme } from "../theme/CustomThemeProvider";
import { LoadingButton } from "@mui/lab";

export default function GoogleSignInButton({
  onMfa,
}: {
  onMfa: (resolver: firebase.auth.MultiFactorResolver) => void;
}) {
  const { loginWithGoogle, attemptingLogin } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useCustomTheme();

  async function onGoogleSignInSuccess(responseGoogle: any) {
    enqueueSnackbar("Logging in", {
      variant: "success",
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      ),
    });

    try {
      const loginRes = await loginWithGoogle(responseGoogle);

      if (!loginRes?.user?.uid) {
        enqueueSnackbar("Login failed.", {
          variant: "error",
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });
      }
    } catch (error: UnknownError) {
      const isMFARequired = error.code === "auth/multi-factor-auth-required";
      if (isMFARequired) {
        onMfa(error.resolver);
        return;
      }
    }
  }

  function renderButtonUI(props: { onClick: () => void }) {
    return (
      <LoadingButton
        fullWidth
        size="large"
        variant="outlined"
        onClick={props.onClick}
        disabled={attemptingLogin}
        sx={{
          mt: 2,
          borderColor: theme.styles.stroke["stroke-black"],
          backgroundColor: theme.styles.colors.white.white,
          "&:hover": {
            borderColor: theme.styles.stroke["stroke-black"],
            backgroundColor: theme.styles.colors.white.white,
          },
        }}
      >
        <Text
          variant={"button2 - medium  (0%)"}
          color={theme.styles.text["text-black"]}
        >
          Login with google
        </Text>
      </LoadingButton>
    );
  }

  if (!webClientId) {
    console.error("webclient id not found");
    return null;
  }

  return (
    <GoogleLogin
      clientId={webClientId}
      buttonText="Login"
      render={renderButtonUI}
      onSuccess={onGoogleSignInSuccess}
      onFailure={(responseGoogle) => console.log("failure", responseGoogle)}
      cookiePolicy={"single_host_origin"}
    />
  );
}
